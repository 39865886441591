// region U. UI Markups
import accessControlIcon from './access_control_icon.svg';
import accessControlLocationsIconsCorporateFactory from './access_control_locations_icons_corporate_factory.svg';
import accessControlLocationsIconsDataCenter from './access_control_locations_icons_data_center.svg';
import accessControlLocationsIconsResidential from './access_control_locations_icons_residential.svg';
import accessControlLocationsIconsSchool from './access_control_locations_icons_school.svg';
import accessControlNumber from './access_control_number.svg';
import applicationsFaceStatistics from './applications_face_statistics.png';
import applicationsFactory from './applications_factory.png';
import applicationsVip from './applications_vip.png';
import blocklistIcon from './blocklist_icon.svg';
import blocklistLocationsIconsResidential from './blocklist_locations_icons_residential.svg';
import blocklistLocationsIconsSupermarket from './blocklist_locations_icons_supermarket.svg';
import blocklistNumber from './blocklist_number.svg';
import faceStatisticsIcon from './face_statistics_icon.svg';
import faceStatisticsLocationsIconsRetail from './face_statistics_locations_icons_retail.svg';
import faceStatisticsNumber from './face_statistics_number.svg';
import headerCover from './header_cover.png';
import headerCoverMobile from './header_cover_mobile.png';
import keyFeaturesEasyInstallation from './key_features_easy_installation.svg';
import keyFeaturesEasyIntergration from './key_features_easy_intergration.svg';
import keyFeaturesEasyManagement from './key_features_easy_management.svg';
import keyFeaturesRedCheck from './key_features_red_check.svg';
import logo from './logo.png';
import video from './video.svg';
import vipIcon from './vip_icon.svg';
import vipLocationsIconsCorporateFactory from './vip_locations_icons_corporate_factory.svg';
import vipLocationsIconsSchool from './vip_locations_icons_school.svg';
import vipLocationsIconsShops from './vip_locations_icons_shops.svg';
import vipNumber from './vip_number.svg';
// endregion

const faceta = {
  accessControl: {
    icon: accessControlIcon,
    locationsIcons: {
      corporateFactory: accessControlLocationsIconsCorporateFactory,
      dataCenter: accessControlLocationsIconsDataCenter,
      residential: accessControlLocationsIconsResidential,
      school: accessControlLocationsIconsSchool,
    },
    number: accessControlNumber,
  },
  applications: {
    faceStatistics: applicationsFaceStatistics,
    locationsIcons: {

    },
    factory: applicationsFactory,
    vip: applicationsVip,
  },
  blocklist: {
    icon: blocklistIcon,
    locationsIcons: {
      residential: blocklistLocationsIconsResidential,
      supermarket: blocklistLocationsIconsSupermarket,
    },
    number: blocklistNumber,
  },
  faceStatistics: {
    icon: faceStatisticsIcon,
    locationsIcons: {
      retail: faceStatisticsLocationsIconsRetail,
    },
    number: faceStatisticsNumber,
  },
  headerCover: {
    desktop: headerCover,
    mobile: headerCoverMobile,
  },
  keyFeatures: {
    easyInstallation: keyFeaturesEasyInstallation,
    easyIntergration: keyFeaturesEasyIntergration,
    easyManagement: keyFeaturesEasyManagement,
    redCheck: keyFeaturesRedCheck,
  },
  logo,
  video,
  vip: {
    icon: vipIcon,
    locationsIcons: {
      corporateFactory: vipLocationsIconsCorporateFactory,
      school: vipLocationsIconsSchool,
      shops: vipLocationsIconsShops,
    },
    number: vipNumber,
  },
};

export { faceta as default };
