// region 1. Platform Libraries
import PropTypes from 'prop-types';
import React from 'react';
// endregion

// region U. UI Markups
import './Industry.scss';
// endregion

const Industry = ({ form, handleChangeForm }) => {
  const insdustryOptions = [
    'System Integration',
    'Security Services',
    'Consulting',
    'Retail',
    'Manufacturing',
    'Construction',
    'Public Sector',
    'Exhibitions',
    'Military',
    'Other',
  ];

  return (
    <div className="industry">
      <div className="industry__title">
        Industry
      </div>
      <div className="industry__container">
        {
          insdustryOptions.map((option) => {
            const optionId = option.toLowerCase().replace(' ', '-');
            return (
              <div className="industry__item" key={option}>
                <label htmlFor={optionId}>
                  <input
                    type="radio"
                    data-name="industry"
                    id={optionId}
                    value={optionId}
                    checked={optionId === form.industry}
                    onChange={handleChangeForm}
                  />
                  <div className="industry__item--name">
                    {option}
                  </div>
                </label>
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

Industry.propTypes = {
  handleChangeForm: PropTypes.func.isRequired,
  form: PropTypes.objectOf(PropTypes.string).isRequired,
};

export { Industry as default };
