// region 1. Platform Libraries
import PropTypes from 'prop-types';
import React, { useState } from 'react';
// endregion

// region 2. Project Libraries
import { homePage } from '../../assets/images';
import './Carousel.scss';
// endregion

const Carousel = ({
  hasControl, hasIndicator, isKeyFeatures, slides,
}) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const swipeLeft = () => {
    if (currentSlideIndex === 0) {
      setCurrentSlideIndex(slides.length - 1);
    } else {
      setCurrentSlideIndex(currentSlideIndex - 1);
    }
  };

  const swipeRight = () => {
    if (currentSlideIndex === (slides.length - 1)) {
      setCurrentSlideIndex(0);
    } else {
      setCurrentSlideIndex(currentSlideIndex + 1);
    }
  };

  return (
    <div
      className={`carousel__container 
      ${hasControl ? 'carousel__container--control' : ''}
      ${hasIndicator ? 'carousel__container--indicator' : ''}
      `}
    >
      <div className={`carousel__slide-container ${isKeyFeatures && 'is-key-features'}`}>
        <div className={`carousel__slide-image ${isKeyFeatures && 'is-key-features'}`}>
          <img src={slides[currentSlideIndex].image.icon || slides[currentSlideIndex].image} alt="" />
        </div>
        {isKeyFeatures ? (
          <div className="carousel__slide__features">
            <div className="carousel__slide__features__number">
              <img src={slides[currentSlideIndex].image.number} alt="" />
            </div>
            <div className="carousel__slide__features__text">
              <div className="carousel__slide__features__text--title">{slides[currentSlideIndex].name}</div>
              <div className="carousel__slide__features__text--description">{slides[currentSlideIndex].description}</div>
            </div>
          </div>
        ) : (
          <div className="carousel__slide-text">
            <div className="carousel__name">{slides[currentSlideIndex].name}</div>
            {hasIndicator && <div className="carousel__type">{slides[currentSlideIndex].type}</div>}
          </div>
        )}
      </div>
      {
        hasControl && (
          <div className="carousel__control-container">
            <button
              className="carousel__control"
              onClick={swipeLeft}
              type="button"
            >
              <img src={homePage.leftControl.iconM} alt="" />
            </button>
            <button
              className="carousel__control"
              onClick={swipeRight}
              type="button"
            >
              <img src={homePage.rightControl.iconM} alt="" />
            </button>
          </div>
        )
      }
      {
        hasIndicator && (
          <div className="carousel__indicator-container">
            {slides.map((slide, index) => (
              <button
                className={`carousel__indicator ${currentSlideIndex === index ? 'carousel__indicator--active' : ''}`}
                type="button"
                onClick={() => { setCurrentSlideIndex(index); }}
                aria-label="indicator"
                key={slide.name}
              />
            ))}
          </div>
        )
      }
    </div>
  );
};

Carousel.propTypes = {
  hasControl: PropTypes.bool.isRequired,
  hasIndicator: PropTypes.bool.isRequired,
  isKeyFeatures: PropTypes.bool,
  slides: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.element,
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
  })).isRequired,
};

Carousel.defaultProps = {
  isKeyFeatures: false,
};

export default Carousel;
