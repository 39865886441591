// region 1. Platform Libraries
import PropTypes from 'prop-types';
import React from 'react';
// endregion

// region U. UI Markups
import './BottomBackground.scss';
// endregion

const BottomBackground = ({ isHome, isPeer, isServices }) => (
  <div className={`bottom-background bottom-background${isHome ? '--home' : ''} bottom-background${isPeer ? '--peer' : ''} ${isServices ? 'is-services' : ''}`}>
    {(isHome || isServices) && (
      <div className="bottom-background__applications-section">
        <div className="bottom-background__applications-section--title" />
        <div className="bottom-background__applications-section--block" />
      </div>
    )}
    {!isPeer && (
      <div className="bottom-background__cases-section">
        <div className="bottom-background__cases-section--title" />
        <div className="bottom-background__cases-section--content" />
      </div>
    )}
    <div className="bottom-background__contact-us-section">
      <div className="bottom-background__contact-us-section--title" />
      <div className="bottom-background__contact-us-section--content" />
    </div>
    <div className="bottom-background__footer" />
  </div>
);

export default BottomBackground;

BottomBackground.propTypes = {
  isHome: PropTypes.bool,
  isPeer: PropTypes.bool,
  isServices: PropTypes.bool,
};

BottomBackground.defaultProps = {
  isHome: false,
  isPeer: false,
  isServices: false,
};
