// region 1. Platform Libraries
import React from 'react';
// endregion

// region U. UI Markups
import images from '../../assets/images';
import './ArisanContactInformation.scss';
// endregion

const ArisanContactInformation = () => (
  <div className="arisan-contact-information">
    <div className="arisan-contact-information__logo">
      <img src={images.arisan.logo} alt="" />
    </div>
    <div className="arisan-contact-information__contact-information">
      <div className="arisan-contact-information__contact-information--red">
        <a href="tel:+886227670367">
          +886-2-2767-0367
        </a>
      </div>
      <div className="arisan-contact-information__contact-information--red">
        <a href="mailto:info@arisan.io">
          info@arisan.io
        </a>
      </div>
      <a target="_blank" rel="noopener noreferrer" href="https://goo.gl/maps/Xu6nASfVffB2" className="address">
        <div className="arisan-contact-information__contact-information--gray">
          Rm.701, 7F No.205 Sec 1 Dunhua S Rd
        </div>
        <div className="arisan-contact-information__contact-information--gray">
          Daan Dist Taipei City 106 Taiwan
        </div>
      </a>
    </div>
  </div>
);

export default ArisanContactInformation;
