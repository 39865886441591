// region 1. Platform Libraries
import PropTypes from 'prop-types';
import React, { useState } from 'react';
// endregion

// region 2. Project Libraries
import countries from './countries.json';
// endregion

// region U. UI Markups
import './CustomInfo.scss';
// endregion

const RenderCountries = ({ country }) => {
  const countriesSelections = Object.values(countries).sort();

  return (
    <div className="country__dropdown-container">
      {
        countriesSelections.map((select) => (
          <div
            className={`country__dropdown-container--item ${country === select && 'active'}`}
            data-value={select}
            key={select}
            data-name="country"
          >
            {select}
          </div>
        ))
      }
    </div>
  );
};

const CustomInfo = ({ form, handleChangeForm, isPricingRequset }) => {
  const [isShowDropdown, setIsShowDropdown] = useState(false);

  const handleDropdown = (e) => {
    e.preventDefault();
    handleChangeForm(e);
    setIsShowDropdown(!isShowDropdown);
  };

  return (
    <>
      <div className="custom-info">
        <label htmlFor="name">
          <div className="custom-info__item">
            <span className="custom-info__item-name required">Name </span>
            <span className="custom-info__required">
              (Required
              <span>
                *
              </span>
              )
            </span>
          </div>
          <input
            onChange={handleChangeForm}
            type="text"
            name="information"
            id="name"
            data-name="name"
            placeholder="Your name"
            value={form.name || ''}
          />
        </label>
      </div>
      {
        isPricingRequset && (
          <div className="custom-info">
            <label htmlFor="organization">
              <div className="custom-info__item-name">
                Organization
              </div>
              <input
                onChange={handleChangeForm}
                type="text"
                name="information"
                id="organization"
                data-name="organization"
                placeholder="Your company"
                value={form.organization || ''}
              />
            </label>
          </div>
        )
      }
      <div className="custom-info">
        <label htmlFor="email">
          <div className="custom-info__item-name required">
            Email
          </div>
          <input
            onChange={handleChangeForm}
            type="email"
            name="information"
            id="email"
            data-name="email"
            placeholder="ex: 123abc@example.com"
            value={form.email || ''}
          />
        </label>
      </div>
      <div className="custom-info">
        <label htmlFor="phone">
          <div className={`custom-info__item-name ${isPricingRequset && 'required'}`}>
            phone
          </div>
          <input
            onChange={handleChangeForm}
            type="phone"
            name="information"
            id="phone"
            data-name="phone"
            placeholder="+1-000-0000-000"
            value={form.phone || ''}
          />
        </label>
      </div>

      {
        isPricingRequset && (
          <div className="custom-info">
            <label htmlFor="country">
              <div className="custom-info__item-name country__title required">
                Where do you intend to deploy our services?
              </div>
              <div
                className={`country ${isShowDropdown && 'show'}`}
                aria-hidden
                onClick={handleDropdown}
              >
                <input
                  type="text"
                  name="information"
                  id="country"
                  data-name="country"
                  placeholder={`${form.country ? form.country : 'Choose one'}`}
                  value={form.country || ''}
                  readOnly
                />
                {isShowDropdown && <RenderCountries country={form.country || ''} />}
                <i className="country__dropdown-arrow" />
              </div>
            </label>
          </div>
        )
      }
    </>
  );
};

CustomInfo.propTypes = {
  form: PropTypes.objectOf(PropTypes.string).isRequired,
  handleChangeForm: PropTypes.func.isRequired,
  isPricingRequset: PropTypes.bool.isRequired,
};

RenderCountries.propTypes = {
  country: PropTypes.string.isRequired,
};

export default CustomInfo;
