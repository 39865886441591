// region 1. Platform Libraries
import React from 'react';
// endregion

// region 2. Project Libraries
import ArisanInfo from '../../components/ArisanInfo';
import Header from '../../components/Header';
import FacetaContent from '../../components/FacetaContent';
// endregion

// region U. UI Markups
import './Faceta.scss';
// endregion

const Faceta = () => (
  <div className="faceta">
    <Header />
    <FacetaContent />
    <ArisanInfo />
  </div>
);

export default Faceta;
