// region 1. Platform Libraries
import React from 'react';
// endregion

// region 2. Project Libraries
import ArisanInfo from '../../components/ArisanInfo';
import Header from '../../components/Header';
import PeerContent from '../../components/PeerContent';
// endregion

// region U. UI Markups
import './Peer.scss';
// endregion

const Peer = () => (
  <div className="peer">
    <Header />
    <PeerContent />
    <ArisanInfo showCases={false} />
  </div>
);

export default Peer;
