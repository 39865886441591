// region 1. Platform Libraries
import PropTypes from 'prop-types';
import React from 'react';
// endregion

// region U. UI Markups
import images from '../../assets/images';
import './PdfDownload.scss';
// endregion

const PdfDownload = ({ file, productName }) => (
  <div className="pdf-download">
    <div className="pdf-download__text">
      {`• ${productName}  PDF`}
    </div>
    <a href={file} download={`${productName}_brochure`}>
      <div className="pdf-download__button">
        <div className="pdf-download__button--text">
          Download Brochure
        </div>
        <div className="pdf-download__button--icon">
          <img src={images.pdfDownloadIcon} alt="" />
        </div>
      </div>
    </a>
  </div>
);

export default PdfDownload;

PdfDownload.propTypes = {
  file: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
};
