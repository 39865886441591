// region 1. Platform Libraries
import PropTypes from 'prop-types';
import React from 'react';
// endregion

// region U. UI Markups
import './ArisanTitle.scss';
// endregion

const ArisanTitle = ({ title, className, color }) => (
  <div className={`arisan-title arisan-h2-normal ${color} ${className}`}>
    <div className="arisan-title__text">
      {title}
    </div>
    <div className="arisan-title__underline" />
  </div>
);

export default ArisanTitle;

ArisanTitle.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
};

ArisanTitle.defaultProps = {
  className: '',
  color: 'black',
};
