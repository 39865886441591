// region 1. Platform Libraries
import PropTypes from 'prop-types';
import React from 'react';
// endregion

// region 2. Project Libraries
import Application from './Application';
import Cases from './Cases';
import ContactUs from './ContactUs';
// endregion

// region U. UI Markups
import './ArisanInfo.scss';
// endregion

const ArisanInfo = ({ showApplication, showCases, showContactUs }) => (
  <div className="arisan-info">
    {showApplication && <Application />}
    {showCases && <Cases />}
    {showContactUs && <ContactUs />}
  </div>
);

ArisanInfo.propTypes = {
  showApplication: PropTypes.bool,
  showCases: PropTypes.bool,
  showContactUs: PropTypes.bool,
};

ArisanInfo.defaultProps = {
  showApplication: false,
  showCases: true,
  showContactUs: true,
};

export default ArisanInfo;
