// region 1. Platform Libraries
import React from 'react';
// endregion

// region U. UI Markups
import videos from '../../assets/videos';
import './HomeBackground.scss';
// endregion

const HomeBackground = () => (
  <div className="home-background">
    <div className="home-background__video-section">
      <div className="home-background__video-blue-cover" />
      <div className="home-background__video-container">
        <video className="home-background__video" playsInline autoPlay muted loop>
          <source src={videos.arisanVideo} type="video/mp4" />
        </video>
      </div>
    </div>
    <div className="home-background__reimagined-section">
      <div className="home-background__reimagined-section--block" />
    </div>
    <div className="home-background__problems-section">
      <div className="home-background__problems-section--block" />
    </div>
    <div className="home-background__slogan-section">
      <div className="home-background__slogan-section--block" />
      <div className="home-background__slogan-section--red" />
    </div>
    <div className="home-background__services-section">
      <div className="home-background__services-section--block" />
    </div>
    <div className="home-background__serverless-section">
      <div className="home-background__serverless-section--block" />
    </div>
  </div>
);

export default HomeBackground;
