// region 1. Platform Libraries
import React from 'react';
import ReactDOM from 'react-dom';
// endregion

// region 2. Project Libraries
import App from './containers/App';
// endregion

// region U. UI Markups
import './index.scss';
// endregion

ReactDOM.render(<App />, document.getElementById('website'));
