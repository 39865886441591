// region 1. Platform Libraries
import React from 'react';
// endregion

// region U. UI Markups
import images from '../../assets/images';
import './ServicesBlockHeader.scss';
// endregion

const ServicesBlockHeader = () => (
  <>
    <header className="header-block">
      <div className="header-block__title">
        SERVICES
      </div>
      <div className="header-block__triangle" />
      <div className="header-block__image">
        <img src={images.services.architecture} alt="" />
      </div>
    </header>
    <div className="side-extend">
      <div className="side-extend__left" />
      <div className="side-extend__right" />
      <div className="side-extend__building" />
    </div>
  </>
);

export default ServicesBlockHeader;
