// region 2. Project Libraries
import clioPage from './clio';
import facetaPage from './faceta';
import homePage from './home';
import peerPage from './peer';
// endregion

// region U. UI Markups
import bill from './about_us_bill.png';
import building from './about_us_building_background.png';
import david from './about_us_david.png';
import shirly from './about_us_shirly.png';
import tim from './about_us_tim.png';
import arisanEarth from './arisan_earth.svg';
import arisanHamburger from './arisan_hamburger.svg';
import arisanLogo from './arisan_logo.svg';
import arisanLogoM from './arisan_logo_m.svg';
import familyMart from './cases_family_mart.png';
import liangFu from './cases_liang_fu.png';
import worldTrend from './cases_world_trend.png';
import broadCasting from './clio_icon_broad_casting.svg';
import deviceMonitoring from './clio_icon_device_monitoring.svg';
import instantNotification from './clio_icon_instant_notification.svg';
import clioLogo from './clio_logo.png';
import clioSecurityRoom from './clio_security_room.png';
import videoArchive from './clio_icon_video_archive.svg';
import contactFormCloseButton from './contact_form_close_button.svg';
import contactFormLogo from './contact_form_logo.png';
import demo from './contact_us_demo.svg';
import info from './contact_us_info.svg';
import pricing from './contact_us_pricing.svg';
import customSolutionsBackground from './custom_solutions_background.png';
import checkbox from './custom_solutions_check_box.png';
import redCheckSign from './custom_solutions_red_check_sign.svg';
import customSolutionsIcon from './custom_solutions_icon.png';
import accessControl from './faceta_icon_access_control.svg';
import blocklist from './faceta_icon_blocklist.svg';
import faceStatistics from './faceta_icon_face_statistics.svg';
import vip from './faceta_icon_vip.svg';
import facetaLogo from './faceta_logo.png';
import pdfDownloadIcon from './pdf_download_icon.svg';
import peerLogo from './peer_logo.png';
import architecture from './services_architecture.png';
import circle from './services_icon_circle.svg';
import moreDetailArrow from './services_more_detail_arrow.svg';

// endregion

const images = {
  aboutUs: {
    bill,
    building,
    david,
    shirly,
    tim,
  },
  arisan: {
    earth: arisanEarth,
    hamburger: arisanHamburger,
    logo: arisanLogo,
    logoM: arisanLogoM,
  },
  cases: {
    familyMart,
    liangFu,
    worldTrend,
  },
  clio: {
    broadCasting,
    deviceMonitoring,
    instantNotification,
    logo: clioLogo,
    securityRoom: clioSecurityRoom,
    videoArchive,
  },
  contactUs: {
    demo,
    info,
    pricing,
  },
  contactForm: {
    closeButton: contactFormCloseButton,
    logo: contactFormLogo,
  },
  customSolutions: {
    background: customSolutionsBackground,
    checkbox,
    icon: customSolutionsIcon,
    redCheckSign,
  },
  faceta: {
    logo: facetaLogo,
    accessControl,
    blocklist,
    faceStatistics,
    vip,
  },
  peer: {
    logo: peerLogo,
  },
  pdfDownloadIcon,
  services: {
    architecture,
    circle,
    arrow: moreDetailArrow,
  },
};

export { images as default };

export {
  clioPage, facetaPage, homePage, peerPage,
};
