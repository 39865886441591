// region 1. Platform Libraries
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  animateScroll as scroll, Element, Events, scroller,
} from 'react-scroll';
// endregion

// region 2. Project Libraries
import ArisanTitle from '../Utils/ArisanTitle';
// endregion

// region U. UI Markups
import images from '../../assets/images';
import './AboutUsContent.scss';
// endregion

const createTeamMemberCard = (memberInfo) => (
  <>
    {memberInfo ? (
      <div className="team__member-card">
        <div className="team__member-card__container">
          <div className="team__member-card__avatar">
            <img src={memberInfo.imageUrl} alt="" />
          </div>
          <div className="team__member-card__name arisan-h2-normal">
            {memberInfo.name}
          </div>
          <div className="team__member-card__job-title">
            {memberInfo.jobTitle}
          </div>
        </div>
      </div>
    ) : (
      <div className="team__member-card">
        <a href="https://www.104.com.tw/company/1a2x6bjl0o" target="_blank" rel="noreferrer">
          <div className="team__member-card__empty">
            JOIN US
          </div>
        </a>
      </div>
    )}
  </>
);

const AboutUsContent = () => {
  useEffect(() => {
    Events.scrollEvent.register('begin');
    Events.scrollEvent.register('end');
    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  const { pathname } = useLocation();
  useEffect(() => {
    const directTo = pathname.replace('/about-us/', '');

    if (directTo !== '/about-us') {
      window.scrollTo(0, 0);
      setTimeout(() => { /// Need to wait for load the image
        scroller.scrollTo(directTo, {
          delay: 0,
          duration: 800,
          offset: 0,
          smooth: true,
        });
      }, 800);
    } else {
      setTimeout(() => {
        scroll.scrollToTop();
      }, 800);
    }
  }, [pathname]);

  return (
    <>
      {/* <div className="content">
        <div className="content__company-information-container">
          <div className="company-information">
            <p className="company-information__first-paragraph-title arisan-h2-normal">
              COMPANY INFORMATION
            </p>
            <div className="company-information__underline" />
            <p className="company-information__first-paragraph arisan-h3">
              Established: September 25, 2015
              <br />
              Number of Employees: 7
              <br />
              Paid-In Capital: TWD4,500,000
            </p>
          </div>
        </div>
      </div> */}
      <div className="mission">
        <Element id="mission" name="mission" className="element-position" />
        <ArisanTitle title="MISSION &amp; VISION" color="white" />
        <div className="mission__content">Our mission is to boost clients’ operational efficiency by applying both edge computing and cloud computing technologies. We aim to inspire our tribe to help bringing in a new era of human awaking by embracing artificial intelligence — an era where humans only engages in creative ventures that only human can.</div>
        <ArisanTitle title="VALUES" color="white" />
        <div className="mission__content">Time travels in only one direction and human attention is the most valuable resource in our world. People should direct precious effort and energy onto those tasks where only human can accomplish. Mundane repetitive chores could be completed more efficiently with help of machines. We embrace automation and artificial intelligence. We offer straightforward solutions to our clients.</div>
      </div>
      <div className="team">
        <Element id="team" name="team" className="element-position" />
        <ArisanTitle title="TEAM" />
        <div className="team__members">
          <div className="team__members__member">
            {createTeamMemberCard({
              imageUrl: images.aboutUs.david,
              jobTitle: 'Co-founder and CTO of ARISAN Inc.',
              name: 'David Tang',
            })}
          </div>
          <div className="team__members__quote arisan-h2-normal">“A software engineering veteran who wrote software for governments, telecom companies and other private sectors. More than 10 years experience in video surveillance product management and product distribution covering 3 continents. Believes in automation and loves optimization.”</div>
          <div className="team__members__quote arisan-h2-normal">“Sales with engineering background. For more than 10 years with proven resourcefulness and strong technical knowledge, facilitated clients through more than 100 million US dollars worth of projects of all sizes ranging from those with dozens of cameras to one with thousands of cameras. With current market dynamics, cloud services can even facilitate further to reach even more projects. Delighted to partner with strong players to change the industry.”</div>
          <div className="team__members__member">
            {createTeamMemberCard({
              imageUrl: images.aboutUs.bill,
              jobTitle: 'Co-founder and Sales VP of Arisan Inc.',
              name: 'Bill Tang',
            })}
          </div>
          <div className="team__members__member">
            {createTeamMemberCard({
              imageUrl: images.aboutUs.shirly,
              jobTitle: 'CXO',
              name: 'Shirly Chou',
            })}
          </div>
          <div className="team__members__member">
            {createTeamMemberCard({
              imageUrl: images.aboutUs.tim,
              jobTitle: 'Full Stack Developer',
              name: 'Tim Chen',
            })}
          </div>
          <div className="team__members__member">
            {createTeamMemberCard()}
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsContent;
