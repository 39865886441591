// region 1. Platform Libraries
import React from 'react';
// endregion

// region 2. Project Libraries
import ArisanInfo from '../../components/ArisanInfo';
import Header from '../../components/Header';
import ServicesContent from '../../components/ServicesContent';
// endregion

// region U. UI Markups
import './Services.scss';
// endregion

const Services = () => (
  <div className="services">
    <Header />
    <ServicesContent />
    <ArisanInfo showApplication />
  </div>
);

export default Services;
