// region 1. Platform Libraries
import React from 'react';
// endregion

// region U. UI Markups
import './ServicesBackground.scss';
// endregion

const ServicesBackground = () => (
  <div className="services-background">
    <div className="services-background__product">
      <div className="services-background__product--block" />
      <div className="services-background__product--bottom" />
    </div>
  </div>
);

export default ServicesBackground;
