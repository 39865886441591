// region 1. Platform Libraries
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
// endregion

// region 2. Project Libraries
import images from '../../assets/images';
import ArisanContactInformation from '../Utils/ArisanContactInformation';
import CustomInfo from './CustomInfo';
import Industry from './Industry';
// endregion

// region U. UI Markups
import './ContactForm.scss';
// endregion

const ContactForm = ({ closeContactForm, contactFormRequset }) => {
  const defaultForm = {
    name: '',
    organization: '',
    email: '',
    phone: '',
    industry: 'system-integration',
    message: '',
    country: '',
  };

  const [customRequest, setCustomRequest] = useState([contactFormRequset]);
  const [form, setForm] = useState(defaultForm);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const isPricingRequset = customRequest.some((req) => req === 'Price Inquiry');

  useEffect(() => {
    if (!isPricingRequset) {
      if (form.name && form.email) {
        setDisableSubmit(false);
      } else {
        setDisableSubmit(true);
      }
    } else if (isPricingRequset) {
      if (form.name && form.email && form.phone && form.country) {
        setDisableSubmit(false);
      } else {
        setDisableSubmit(true);
      }
    }
  }, [form.name, form.email, form.phone, form.country, isPricingRequset]);

  const handleChangeRequest = ({ target }) => {
    const isExistent = customRequest.some((res) => res === target.value);

    let newRequest;
    if (isExistent) {
      newRequest = customRequest.filter((res) => res !== target.value);
    } else {
      newRequest = customRequest.concat([target.value]);
    }

    if (newRequest.length < 1) {
      return;
    }

    setCustomRequest(newRequest);
  };

  const handleChangeForm = (e) => {
    const name = e.target.getAttribute('data-name');
    const newValue = e.target.value || e.target.getAttribute('data-value');

    setForm({ ...form, [name]: newValue });
  };

  const handleSubmit = () => {
    const validateTextInputs = () => {
      const W3C_EMAIL_REGEX = RegExp(
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      );

      const { email } = form;
      if (!W3C_EMAIL_REGEX.test(email)) {
        throw new Error('invalid email address pattern');
      }
    };

    const sendFormValue = () => {
      const sendDataFormat = {
        Name: form.name,
        Organizatio: form.organization,
        Email: form.email,
        Phone: form.phone,
        Country: form.country,
        Industry: form.industry,
        Message: form.message,
        'More Information': customRequest.some((req) => req === 'More Information'),
        'Demo Request': customRequest.some((req) => req === 'Demo Request'),
        'Price Inquiry': customRequest.some((req) => req === 'Price Inquiry'),
      };

      const borderStyle = 'border: 1px solid #545454;';
      const encodeFormValues = (obj) => (Object.entries(obj)
        .map(([key, value]) => (
          '<tr>'
          + `<td style="padding:2px;${borderStyle}">${key}</td>`
          + `<td style="padding:2px;${borderStyle}">${value}</td>`
          + '</tr>'
        ))
        .join(''));

      const formTable = `<html><table style="border-collapse:collapse;${borderStyle}">`
        + `${encodeFormValues(sendDataFormat)}`
        + '</table></html>';

      fetch('http://hubble.arisan.io/jobs', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        body: JSON.stringify({ type: 'ANNOUNCE_NEW_CONTACT', contact_data: formTable }),
      });
    };

    try {
      if (disableSubmit) {
        return;
      }
      validateTextInputs();
      sendFormValue();
      alert('Thank you for your information.\nWe will contact you soon.');
      closeContactForm();
    } catch (e) {
      alert(e.message);
    }
  };

  return (
    <div className="contact-form">
      <div className="contact-form__form-container">
        <div className="form">
          <button
            type="button"
            onClick={closeContactForm}
            className="form__close-button"
          >
            <img src={images.contactForm.closeButton} alt="" />
          </button>
          <div className="form__header">
            <div className="form__header--title arisan-h1-normal">
              Contact Us
            </div>
          </div>
          <div className="form__checkbox">
            <div className="form__checkbox--item arisan-h3-normal">
              <label
                htmlFor="more-information"
              >
                <input
                  checked={customRequest.some((item) => item === 'More Information')}
                  id="more-information"
                  onChange={handleChangeRequest}
                  name="request"
                  type="checkbox"
                  value="More Information"
                />
                <div className={`form__checkbox--text ${customRequest.length === 1 && 'not-allowed'}`}>
                  More Information
                </div>
              </label>
            </div>

            <div className="form__checkbox--item arisan-h3-normal">
              <label
                htmlFor="demo-request"
              >
                <input
                  checked={customRequest.some((item) => item === 'Demo Request')}
                  id="demo-request"
                  onChange={handleChangeRequest}
                  name="request"
                  type="checkbox"
                  value="Demo Request"
                />
                <div className={`form__checkbox--text ${customRequest.length === 1 && 'not-allowed'}`}>
                  Demo Request
                </div>
              </label>
            </div>

            <div className="form__checkbox--item arisan-h3-normal">
              <label
                htmlFor="price-inquiry"
              >
                <input
                  checked={customRequest.some((item) => item === 'Price Inquiry')}
                  id="price-inquiry"
                  onChange={handleChangeRequest}
                  name="request"
                  type="checkbox"
                  value="Price Inquiry"
                />
                <div className={`form__checkbox--text ${customRequest.length === 1 && 'not-allowed'}`}>
                  Price Inquiry
                </div>
              </label>
            </div>
          </div>
          <div className="form__underline" />
          <div className="form__content-box arisan-footnote">
            <div className="form__content">
              <CustomInfo {...{ form, handleChangeForm, isPricingRequset }} />
            </div>
            <div className="form__content">
              <Industry {...{ form, handleChangeForm }} />
            </div>
            <div className="form__content">
              <div className="custom-message">
                <label htmlFor="message">
                  <div className="custom-message__title">
                    Message
                  </div>
                  <textarea
                    id="message"
                    data-name="message"
                    onChange={handleChangeForm}
                    className="custom-message__textarea"
                    cols="20"
                    placeholder="If you could kindly describe the prospective usage/application of our services, we can respond to your request much better."
                    value={form.message || ''}
                  />
                </label>
                <button
                  className={`form__submit arisan-h3-normal ${disableSubmit && 'disable'}`}
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-form__contact-info arisan-bold">
          <ArisanContactInformation />
        </div>
        <img className="contact-form__contact-info-background" src={images.contactForm.logo} alt="" />
      </div>
    </div>
  );
};

ContactForm.propTypes = {
  closeContactForm: PropTypes.func.isRequired,
  contactFormRequset: PropTypes.string.isRequired,
};

export default ContactForm;
