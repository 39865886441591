// region 1. Platform Libraries
import React from 'react';
// endregion

// region U. UI Markups
import './ClioBackground.scss';
// endregion

const ClioBackground = () => (
  <div className="clio-background">
    <div className="clio-background__header">
      <div className="clio-background__header--red" />
      <div className="clio-background__header--image" />
    </div>
    <div className="clio-background__how-it-works">
      <div className="clio-background__how-it-works--block" />
    </div>
    <div className="clio-background__key-features">
      <div className="clio-background__key-features--red" />
      <div className="clio-background__key-features--block" />
      <div className="clio-background__key-features--incident-management" />
      <div className="clio-background__key-features--live-broadcasting" />
      <div className="clio-background__key-features--video-archive" />
      <div className="clio-background__key-features--bottom" />
    </div>
    <div className="clio-background__features-detail" />
  </div>
);

export default ClioBackground;
