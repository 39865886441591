// region 1. Platform Libraries
import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Route } from 'react-router-dom';

// endregion

// region 2. Project Libraries
import Background from '../../components/Background';
import ContactForm from '../../components/ContactForm';
import Footer from '../../components/Footer';
import Nav from '../../components/Nav';
import ContextStore from '../../store/Context';
import AboutUs from '../AboutUs';
import Clio from '../Clio';
import Faceta from '../Faceta';
import Home from '../Home';
import Peer from '../Peer';
import Services from '../Services';
// endregion

// region U. UI Markups
import './App.scss';
// endregion

const App = () => {
  /* If contactFormRequest is empty string, it mean not show form */
  const [contactFormRequset, setContactFormRequset] = useState('');

  const showContactForm = (text) => {
    setContactFormRequset(text);
  };

  const closeContactForm = () => (setContactFormRequset(''));

  useEffect(() => {
    const bodySelect = document.querySelector('body');
    if (contactFormRequset) {
      bodySelect.style.overflowY = 'hidden';
    } else {
      bodySelect.style.overflowY = 'auto';
    }
  }, [contactFormRequset]);

  return (
    <ContextStore.Provider value={{ showContactForm }}>
      <Router>
        <div className="app arisan-body">
          <Route exact path="/">
            <Nav isHome="true" />
            <Home />
          </Route>
          <Route exact path="/services">
            <Nav />
            <Services />
          </Route>
          <Route exact path="/custom-solutions">
            <Nav />
            <Services />
          </Route>
          <Route path="/clio">
            <Nav />
            <Clio />
          </Route>
          <Route path="/faceta">
            <Nav />
            <Faceta />
          </Route>
          <Route path="/peer">
            <Nav />
            <Peer />
          </Route>
          <Route path="/about-us">
            <Nav />
            <AboutUs />
          </Route>

          <Footer />
          <Background />
          {contactFormRequset && <ContactForm {...{ closeContactForm, contactFormRequset }} />}
        </div>
      </Router>
    </ContextStore.Provider>
  );
};

export default App;
