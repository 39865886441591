// region 1. Platform Libraries
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Events, scroller, Element } from 'react-scroll';
// endregion

// region 2. Project Libraries
import ContextStore from '../../store/Context';
import ArisanTitle from '../Utils/ArisanTitle';
// endregion

// region U. UI Markups
import images from '../../assets/images';
import './ServicesContent.scss';
// endregion

const CustomizeSolutions = () => {
  const solutionsContent = [
    'White Labeling',
    'Custom Branding',
    'Special Intergrations',
  ];
  return (
    <div className="customize__solutions">
      {solutionsContent.map((solution) => (
        <div className="customize__solution" key={solution}>
          <div className="customize__solution--checkbox">
            <img src={images.customSolutions.checkbox} alt="" />
          </div>
          <div className="customize__solution--red-sign">
            <img src={images.customSolutions.redCheckSign} alt="" />
          </div>
          <div className="customize__solution--text arisan-h3">
            {solution}
          </div>
        </div>
      ))}
    </div>
  );
};

const ProductIcons = ({ icons }) => (
  <div className="icons">
    {icons.map((icon) => (
      <Link className="icons__link" to={icon.to} key={icon.to}>
        <img src={icon.image} alt="" />
      </Link>
    ))}
  </div>
);

const Product = ({ introduction, name, title }) => (
  <div id="peer" className="services-product">
    <div className="services-product__title">
      <ArisanTitle title={title} color="white" />
    </div>
    <div className="services-product__content">
      <div className="services-product__introduction">
        <div
          className={`services-product__introduction__title
          ${name === 'faceta' && 'isFaceta'}
          ${name === 'peer' && 'isPeer'}
        `}
        >
          {introduction.title}
        </div>
        <div className="services-product__introduction__content">{introduction.content}</div>
        {introduction.icons && (
          <ProductIcons icons={introduction.icons} />
        )}
        <MoreDetailButton name={name} />
      </div>
      <div className="services-product__logo">
        <img src={introduction.image} alt="" />
      </div>
    </div>
  </div>
);

const MoreDetailButton = ({ name }) => (
  <Link className="more-detail" to={`/${name}`}>
    <div className="more-detail__container">
      <div className="more-detail__container__text">
        More Details
      </div>
      <img className="more-detail__container__arrow" src={images.services.arrow} alt="" />
    </div>
  </Link>
);

const facetaIcons = [
  {
    to: '/faceta/access-control',
    image: images.faceta.accessControl,
  },
  {
    to: '/faceta/vip',
    image: images.faceta.vip,
  },
  {
    to: '/faceta/blocklist',
    image: images.faceta.blocklist,
  },
  {
    to: '/faceta/face-statistics',
    image: images.faceta.faceStatistics,
  },
];

const facetaIntroduction = {
  content: 'Faceta® provides realtime notifications on VIP / blocklist events, face database synchronizations, device health monitoring, event search / filtering / management, incident history exports and user management.',
  icons: facetaIcons,
  image: images.faceta.logo,
  title: `Face Recognition
  Reimagined.`,
};

const peerIntroduction = {
  content: 'Peer™ Series is a powerful solution for deploying and remotely managing large-scale video surveillance projects. Peer Series greatly improves installation efficiency and is compatible with most cameras.',
  image: images.peer.logo,
  title: `P2P Video Security
  For Business`,
};

const ServicesContent = () => {
  const { showContactForm } = useContext(ContextStore);

  useEffect(() => {
    Events.scrollEvent.register('begin');
    Events.scrollEvent.register('end');
    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname === '/custom-solutions') {
      setTimeout(() => { /* Need to wait for load the image */
        scroller.scrollTo('custom-solutions', {
          delay: 0,
          duration: 800,
          offset: 0,
          smooth: true,
        });
      }, 500);
    }
  }, [pathname]);

  return (
    <>
      <div id="clio" className="services-clio">
        <div className="services-clio__title">
          <div className="services-clio__title--text arisan-h2">
            1. CliO
          </div>
          <div className="services-clio__title--underline" />
        </div>
        <div className="services-clio__content">
          <div className="logo">
            <div className="logo__container">
              <img src={images.clio.logo} alt="" />
            </div>
          </div>
          <div className="services-clio__introduction">
            <div className="introduction">
              <div className="introduction__title arisan-h1">
                Next Generation&nbsp;
                <br className="mobile-break" />
                Video Security
              </div>
              <div className="introduction__content">
                CliO® is a video security service platform&nbsp;
                <br className="mobile-break" />
                that records&nbsp;
                <br className="desktop-break" />
                surveillance videos
                {' '}
                {'&'}
                {' '}
                IoT&nbsp;
                <br className="mobile-break" />
                sensor telemetry and provides&nbsp;
                <br className="desktop-break" />
                incident/&nbsp;
                <br className="mobile-break" />
                device management capabilities.
              </div>
              <div className="introduction__icons">
                <Link className="introduction__link" to="/clio/device-health-monitoring">
                  <div className="introduction__icon">
                    <img src={images.services.circle} alt="" />
                    <img src={images.clio.deviceMonitoring} alt="" />
                  </div>
                </Link>
                <Link className="introduction__link" to="/clio/incident-management">
                  <div className="introduction__icon">
                    <img src={images.services.circle} alt="" />
                    <img src={images.clio.instantNotification} alt="" />
                  </div>
                </Link>
                <Link className="introduction__link" to="/clio/live-broadcasting">
                  <div className="introduction__icon">
                    <img src={images.services.circle} alt="" />
                    <img src={images.clio.broadCasting} alt="" />
                  </div>
                </Link>
                <Link className="introduction__link" to="/clio/video-archive">
                  <div className="introduction__icon">
                    <img src={images.services.circle} alt="" />
                    <img src={images.clio.videoArchive} alt="" />
                  </div>
                </Link>
              </div>
              <MoreDetailButton name="clio" />
            </div>
          </div>
        </div>
        <div className="services-clio__bottom">
          <div className="services-clio__bottom--image">
            <img src={images.clio.securityRoom} alt="" />
          </div>
        </div>
      </div>
      <div id="faceta">
        <Product title="2. Faceta" introduction={facetaIntroduction} name="faceta" />
      </div>
      <div className="services-custom-solutions__top">
        <div className="services-custom-solutions__top--image">
          <img src={images.customSolutions.background} alt="" />
        </div>
      </div>
      <div id="peer">
        <Product title="3. Peer" introduction={peerIntroduction} name="peer" />
      </div>
      <div className="services-custom-solutions">
        <div className="services-custom-solutions__title">
          <Element id="custom-solutions" name="custom-solutions" className="element-position" />
          <div className="services-custom-solutions__title--text arisan-h2">
            4. CUSTOM SOLUTIONS
          </div>
          <div className="services-custom-solutions__title--underline" />
        </div>
        <div className="services-custom-solutions__content ">
          <div className="services-custom-solutions__introduction">
            <div className="introduction">
              <div className="introduction__title arisan-h1">
                What do we do?
              </div>
              <div className="introduction__content">
                Arisan Inc. have designed security or&nbsp;
                <br className="mobile-break" />
                cloud services for&nbsp;
                <br className="desktop-break" />
                wide range of projects&nbsp;
                <br className="mobile-break" />
                from small businesses to military&nbsp;
                <br className="desktop-break" />
                uses.&nbsp;
                <br className="mobile-break" />
                We understand that customization can&nbsp;
                <br className="mobile-break" />
                be a large&nbsp;
                <br className="desktop-break" />
                part of project fulfillment. As&nbsp;
                <br className="mobile-break" />
                we aim to empower you to&nbsp;
                <br className="desktop-break" />
                fulfill your&nbsp;
                <br className="mobile-break" />
                projects, we welcome all discussions on&nbsp;
                <br className="mobile-break" />
                any&nbsp;
                <br className="desktop-break" />
                aspect of customizations and strive&nbsp;
                <br className="mobile-break" />
                hard to deliver them.
              </div>
              <div className="introduction__more-detail" onClick={() => showContactForm('More Information')} aria-hidden>
                <div className="introduction__more-detail--text">
                  Inquiry
                </div>
                <img
                  className="introduction__more-detail--arrow"
                  src={images.services.arrow}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="services-custom-solutions__customize">
            <div className="customize">
              <div className="customize__icon">
                <img
                  className="customize__icon--image"
                  src={images.customSolutions.icon}
                  alt=""
                />
              </div>
              <CustomizeSolutions />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Product.propTypes = {
  introduction: PropTypes.shape({
    content: PropTypes.string,
    icons: PropTypes.arrayOf(PropTypes.shape({
      image: PropTypes.string,
      to: PropTypes.string,
    })),
    image: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

ProductIcons.propTypes = {
  icons: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.string,
    to: PropTypes.string,
  })).isRequired,
};

MoreDetailButton.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ServicesContent;
