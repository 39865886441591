// region 1. Platform Libraries
import PropTypes from 'prop-types';
import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
// endregion

// region U. UI Markups
import './SubNav.scss';
// endregion

const SubServices = (props) => {
  const { navbarServicesBorder } = props;
  return (
    <div className="sub-nav">
      <div className={`sub-nav__item ${!!useRouteMatch('/clio') && 'active'}`}>
        <Link className={`sub-nav__item--link${navbarServicesBorder}`} to="/clio">
          CliO
        </Link>
      </div>
      <div className={`sub-nav__item ${!!useRouteMatch('/faceta') && 'active'}`}>
        <Link className={`sub-nav__item--link${navbarServicesBorder}`} to="/faceta">
          Faceta
        </Link>
      </div>
      <div className={`sub-nav__item ${!!useRouteMatch('/peer') && 'active'}`}>
        <Link className={`sub-nav__item--link${navbarServicesBorder}`} to="/peer">
          Peer
        </Link>
      </div>
      <div className={`sub-nav__item ${!!useRouteMatch('/custom-solutions') && 'active'}`}>
        <Link className={`sub-nav__item--link${navbarServicesBorder}`} to="/custom-solutions">
          CUSTOM SOLUTIONS
        </Link>
      </div>
    </div>
  );
};

SubServices.propTypes = {
  navbarServicesBorder: PropTypes.string,
};

SubServices.defaultProps = {
  navbarServicesBorder: '',
};

export default SubServices;
