// region 1. Platform Libraries
import PropTypes from 'prop-types';
import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
// endregion

// region U. UI Markups
import './SubNav.scss';
// endregion

const SubAboutUs = (props) => {
  const { navbarAboutUsBorder } = props;
  return (
    <div className="sub-nav">
      <div className={`sub-nav__item ${!!useRouteMatch('/about-us/mission') && 'active'}`}>
        <Link className={`sub-nav__item--link${navbarAboutUsBorder}`} to="/about-us/mission">
          MISSION
        </Link>
      </div>
      <div className={`sub-nav__item ${!!useRouteMatch('/about-us/team') && 'active'}`}>
        <Link className={`sub-nav__item--link${navbarAboutUsBorder}`} to="/about-us/team">
          TEAM
        </Link>
      </div>
    </div>
  );
};

SubAboutUs.propTypes = {
  navbarAboutUsBorder: PropTypes.string,
};

SubAboutUs.defaultProps = {
  navbarAboutUsBorder: '',
};

export default SubAboutUs;
