// region 1. Platform Libraries
import React from 'react';
// endregion

// region U. UI Markups
import './BottomBackground.scss';
// endregion

const BottomFooterBackground = () => (
  <div className="bottom-background">
    <div className="bottom-background__footer" />
  </div>
);

export default BottomFooterBackground;
