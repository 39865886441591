// region 1. Platform Libraries
import React from 'react';
// endregion

// region 2. Project Libraries
import AboutUsContent from '../../components/AboutUsContent';
import Header from '../../components/Header';
// endregion

// region U. UI Markups
import './AboutUs.scss';
// endregion

const AboutUs = () => (
  <div className="about-us">
    <Header />
    <AboutUsContent />
  </div>
);

export default AboutUs;
