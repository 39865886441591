// region 1. Platform Libraries
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { animateScroll as scroll } from 'react-scroll';
// endregion

// region 2. Project Libraries
import PeerBrochureEn from '../../assets/downloads/Peer_Brochure_En_211104.pdf';
import { ArisanTitle, PdfDownload } from '../Utils';
// endregion

// region U. UI Markups
import { peerPage } from '../../assets/images';
import videos from '../../assets/videos';
import './PeerContent.scss';
// endregion

const Overview = ({
  description, image, subTitle, title,
}) => (
  <div className="overview">
    <div className="overview__image">
      <img src={image} alt="" />
    </div>
    <div className="overview__title">{title}</div>
    {subTitle && <div className="overview__subTitle">{subTitle}</div>}
    <div className="overview__description">{description}</div>
  </div>
);

const keyFeatures = [
  {
    description: 'Over 3000 locations globally, over 20,000 IP cameras. Our reach is expected to exceed over 10,000 new sites in 2022. With the constant increase in devices, Peer Series management platform is acknowledged for its stability in the market.',
    image: peerPage.keyFeatures.deployments,
    title: `Thousands of Global
    Project Deployments`,
  },
  {
    description: 'Receiving reports from CRM on the unified cloud platform, VSaaS providers could get business insights and understand customers’ needs. CRM allows customization on client access and enables setting up your own sales channels. Peer Series CRM is your go-to tool to replicate the business model with ease.  ',
    image: peerPage.keyFeatures.management,
    title: `Unique CRM
    Customer Relationship
    Management`,
  },
  {
    description: 'With branding customization, VSaaS provider can save a lot of effort on own development and integration. Peer Series is an economical cloud platform with high compatibility and low maintenance. Through the control panel, Peer Series features logo/domain assignment. Labeling the service as your own has never been easier.',
    image: peerPage.keyFeatures.platform,
    title: `Customizable Branding
    on Cloud Platform`,
  },
];

const cloudPlatforms = [
  {
    description: 'Users can look up and review tens of thousands of different sites, not restricted by location and device compatibility. Users can easily playback for a specific time and switch between live and playback. Peer Series is designed for highly intensive workload by providing an intuitive and responsive UI.',
    image: peerPage.platform.liveAndPlayback,
    subTitle: `One Touch to See
    All Your Projects Worldwide`,
    title: 'Live and Playback',
  },
  {
    description: 'Device health reports are generated daily to eliminate the worries over the possibility that device was down. The cloud platform monitors device connectivity regularly by either actively pinging the device or keeping track of device’s heartbeats. Remote site manager could grasp devices’ real-time status faster than on-site users.',
    image: peerPage.platform.deviceHealthMonitoring,
    subTitle: 'Periodic Detection Reports',
    title: 'Device Health Monitoring',
  },
  {
    description: 'When an event is triggered, video feeds are cross-checked and provided along with snapshot and customer information. When a device signal is received, it can be set up to automatically trigger event recording or live broadcasting with pop-up. This feature would enormously improve operator efficiency. ',
    image: peerPage.platform.eventNotifications,
    subTitle: 'with Relevant Video Pop-Up',
    title: 'Event Notifications',
  },
  {
    description: 'CliO® development is collaborated with Microsoft Azure, one of the top three worldwide IaaS providers. Our data center and its 3,500 service staffs received over a hundred certifications to ensure CliO infrastructure complies with international security standards. Additionally, CliO transmits data via HTTPS and 256-bit encryption to ensure security.',
    image: peerPage.platform.safeAndSecure,
    subTitle: `Hundreds of International
    Security Certifications`,
    title: 'Safe and Secure',
  },
];

const hardwareProducts = [
  {
    description:
    ` •  2-5MP indoor/outdoor cameras
      •  Video retrieval after recovery from network failure *
      •  Up to 6 months of cloud recording
      
      *  limited to units with SD card
    `,
    image: peerPage.product.bullet,
    title: 'P2P Bullet Series ',
  },
  {
    description:
    ` •  2-5MP indoor/outdoor cameras
      •  Video retrieval after recovery from network failure *
      •  Up to 6 months of cloud recording

      *  limited to units with SD card
    `,
    image: peerPage.product.dome,
    title: 'P2P Dome Series ',
  },
  {
    description:
    ` •  Supports analog cameras and IP cameras
      •  4/8/16 channels
      •  Video retrieval after recovery from network failure
      •  Up to 6 months of cloud recording
    `,
    image: peerPage.product.dvr,
    title: 'P2P AHD DVR Series',
  },
  {
    description:
    `•  Supports 3rd party IP cameras
      •  4/8/16 channels
      •  Video retrieval after recovery from network failure
      •  Up to 6 months of cloud recording
    `,
    image: peerPage.product.nvr,
    title: 'P2P NVR Series',
  },
];

const PeerContent = () => {
  useEffect(() => {
    setTimeout(() => {
      scroll.scrollToTop();
    }, 800);
  }, []);

  const resetCurrentTime = (e) => {
    e.target.currentTime = 2;
    e.target.play();
  };

  return (
    <>
      <div className="peer-series">
        <div className="peer-series__img">
          <img src={peerPage.series} alt="" />
        </div>
        <div className="peer-series__content">
          {`Designed for business usage, Peer™ Series is a powerful platform for installing and remotely managing large-scale surveillance projects. With the specially designed P2P system, Peer Series improves installation efficiency by simplifying installation processes and eliminating unnecessary steps. With Peer Series technology, existing cameras (analog/IP camera), recorders (NVR/DVR) or IoT devices may be mixed with newly added devices under a unified platform. Peer Series is so flexible in scale that one single operator can manage tens of thousands of cameras globally via the cloud.
            
            Peer Series is easy to install, flexible on compatibility, and scalable. Projects may now be deployed with ease and speed. 
          `}
        </div>
      </div>
      <div className="key-features">
        <div className="key-features__title">
          <ArisanTitle title="KEY FEATURES" />
        </div>
        <div className="key-features__content">
          {keyFeatures.map((keyFeature) => (
            <Overview
              description={keyFeature.description}
              image={keyFeature.image}
              key={keyFeature.title}
              title={keyFeature.title}
            />
          ))}
        </div>
      </div>
      <div className="infrastructure">
        <div className="infrastructure__title">
          <ArisanTitle title="INFRASTRUCTURE" />
        </div>
        <video className="infrastructure__video--horizontal" playsInline autoPlay muted onEnded={resetCurrentTime}>
          <source src={videos.peerInfrastructure.horizontal} type="video/mp4" />
        </video>
        <video className="infrastructure__video--vertical" playsInline autoPlay muted onEnded={resetCurrentTime}>
          <source src={videos.peerInfrastructure.vertical} type="video/mp4" />
        </video>
      </div>
      <div className="clio-cloud-platform">
        <div className="clio-cloud-platform__title">
          <ArisanTitle title="CliO CLOUD PLATFORM" />
        </div>
        <div className="clio-cloud-platform__content">
          {cloudPlatforms.map((cloudPlatform) => (
            <Overview
              description={cloudPlatform.description}
              image={cloudPlatform.image}
              key={cloudPlatform.title}
              subTitle={cloudPlatform.subTitle}
              title={cloudPlatform.title}
            />
          ))}
        </div>
      </div>
      <div className="hardware-products">
        <div className="hardware-products__title">
          <ArisanTitle title="HARDWARE PRODUCTS" />
        </div>
        <div className="hardware-products__content">
          {hardwareProducts.map((hardwareProduct) => (
            <Overview
              description={hardwareProduct.description}
              image={hardwareProduct.image}
              key={hardwareProduct.title}
              subTitle={hardwareProduct.subTitle}
              title={hardwareProduct.title}
            />
          ))}
        </div>
      </div>
      <div className="download">
        <PdfDownload file={PeerBrochureEn} productName="Peer" />
      </div>
    </>
  );
};

Overview.propTypes = {
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

Overview.defaultProps = {
  subTitle: '',
};

export default PeerContent;
