// region 1. Platform Libraries
import moment from 'moment';
import React, { useState, useEffect } from 'react';
// endregion

// region 2. Project Libraries
import ClioBrochureEn from '../../assets/downloads/CliO_Brochure_En_180910.pdf';
import FacetaBrochureEn from '../../assets/downloads/Faceta_Brochure_En_190416.pdf';
import ArisanTitle from '../Utils/ArisanTitle';
import Icon from './Icon';
import Problem from './Problem';
import Service from './Service';
// endregion

// region U. UI Markups
import { homePage } from '../../assets/images';
import './HomeContent.scss';
// endregion

const ChangeVideoSeconds = () => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const currentDay = new Date().getDate();
  const currentHour = new Date().getHours();
  const currentMinute = new Date().getMinutes();
  const currentSecond = new Date().getSeconds();
  const basicVideoSeconds = moment([2018, 6, 1, 0, 0, 0]);
  const currentVideoSeconds = moment([
    currentYear, currentMonth, currentDay, currentHour, currentMinute, currentSecond]);
  const deviceVideoSeconds = currentVideoSeconds.diff(basicVideoSeconds, 'seconds');
  const allOfVideoSeconds = 3155760000 + 5947 * deviceVideoSeconds;
  const commaVideoSeconds = Number(allOfVideoSeconds).toLocaleString();
  return commaVideoSeconds;
};

const clioFeatures = [
  {
    imageUrl: homePage.servicesDeviceHealthMonitoring.icon,
    name: 'Device Health Monitoring',
    path: '/clio/device-health-monitoring',
  },
  {
    imageUrl: homePage.servicesIncidentManagement.icon,
    name: 'Incident Management',
    path: '/clio/incident-management',
  },
  {
    imageUrl: homePage.servicesLiveBroadcasting.icon,
    name: 'Live Broadcasting',
    path: '/clio/live-broadcasting',
  },
  {
    imageUrl: homePage.servicesVideoArchive.icon,
    name: `
      Video
      Archive
    `,
    path: '/clio/video-archive',
  },
];

const facetaFeatures = [
  {
    imageUrl: homePage.servicesAccessControl.icon,
    name: 'Access Control',
    path: '/faceta/access-control',
  },
  {
    imageUrl: homePage.servicesVip.icon,
    name: 'VIP',
    path: '/faceta/vip',
  },
  {
    imageUrl: homePage.servicesBlockList.icon,
    name: 'Blocklist',
    path: '/faceta/blocklist',
  },
  {
    imageUrl: homePage.servicesFaceStatistics.icon,
    name: 'Face Statistics',
    path: '/faceta/face-statistics',
  },
];

const icons = [
  {
    imageUrl: homePage.readyToUse.icon,
    name: 'Ready to Use',
  }, {
    imageUrl: homePage.lowMaintenance.icon,
    name: 'Low Maintenance',
  }, {
    imageUrl: homePage.flexible.icon,
    name: 'Flexible',
  },
];

const HomeContent = () => {
  const [videoSeconds, setVideoSeconds] = useState(0);

  useEffect(() => {
    setVideoSeconds(ChangeVideoSeconds());
    const videoSecondsInterval = setInterval(() => {
      setVideoSeconds(ChangeVideoSeconds());
    }, 1000);
    return () => {
      clearInterval(videoSecondsInterval);
    };
  }, []);

  return (
    <>
      <div className="video-section">
        <div className="video-section__information-container">
          <div className="video-section__red-dot">
            <img
              id="recording-icon"
              src={homePage.rec.icon}
              alt="recording"
              className="video-section__red-dot--blink"
            />
          </div>
          <div
            id="video-counter"
            className="video-section__main-title arisan-roboto-mono"
          >
            {videoSeconds}
          </div>
          <div className="video-section__subtitle">
            <div className="video-section__subtitle-seconds">
              <div className="video-section__subtitle-seconds--line" />
              <div className="video-section__subtitle--text arisan-h3-normal">
                Seconds
              </div>
              <div className="video-section__subtitle-seconds--line" />
            </div>
            <div className="video-section__subtitle-content video-section__subtitle--text arisan-h3-normal">
              Accumulated Video Recordings Since Launch
            </div>
            <div className="video-section__white-line-below" />
          </div>
          <div className="video-section__scroll-down">
            <div className="video-section__scroll-down--text">Scroll Down</div>
            <div className="video-section__scroll-down--icon">
              <img src={homePage.scrollDown.icon} alt="Scroll Down" />
            </div>
          </div>
          <div className="video-section__more">
            <div className="video-section__more--text">more</div>
            <div className="video-section__more--icon">
              <img src={homePage.more.iconM} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="reimagined-section">
        <div className="reimagined-section__content">
          <div className="reimagined-section__red-polygon" />
          <div className="reimagined-section__video-security-text arisan-h1-normal">
            Video Security Reimagined
          </div>
          <div className="reimagined-section__red-polygon" />
        </div>
      </div>
      <div className="problems-section">
        <ArisanTitle
          className="home"
          color="white"
          title="COMMON PROBLEMS"
        />
        <Problem
          description="Not again. Another camera had been out of order for months!"
          highlightText="out of order"
          imageUrl={homePage.problem_1.gif}
          solutionImageUrl={homePage.deviceHealthMonitoring.icon}
          solutionName="Device Health Monitoring"
        />
        <Problem
          description="I don't want to install and learn all 5 softwares to manage these devices."
          highlightText="5 softwares"
          imageUrl={homePage.problem_2.gif}
          solutionImageUrl={homePage.singlePlatformManagement.icon}
          solutionName="Single Platform Management"
        />
        <Problem
          description="Don't make me wait for months to build a new data center."
          highlightText="wait for months"
          imageUrl={homePage.problem_3.gif}
          solutionImageUrl={homePage.instantDeployment.icon}
          solutionName="Instant Deployment"
        />
      </div>
      <div className="slogan-section">
        <div className="slogan-section__title-container">
          <div className="slogan-section__quotation-image slogan-section__quotation-image--top">
            <img src={homePage.topQuotation.icon} alt="" />
          </div>
          <div className="slogan-section__slogan arisan-h1-normal">
            We aim to provide&nbsp;
            <br className="mobile-break" />
            the best experience
            <br className="mobile-break" />
            <br className="desktop-break" />
            for&nbsp;
            <span className="slogan-section__slogan--red">our users</span>
            —
            <span className="slogan-section__slogan--red">
              your&nbsp;
              <br className="mobile-break" />
              clients
            </span>
            .
          </div>
          <div className="slogan-section__quotation-image slogan-section__quotation-image--bottom">
            <img src={homePage.bottomQuotation.icon} alt="" />
          </div>
        </div>
        <div className="slogan-section__description">
          Born in cloud, Arisan’s services are&nbsp;
          <br className="mobile-break" />
          endowed with inheritedly strong
          <br className="mobile-break" />
          <br className="desktop-break" />
          availability, flexibility and&nbsp;
          <br className="mobile-break" />
          scalability.
          <br className="desktop-break" />
          <br className="mobile-break" />
          Video archives are backed by our&nbsp;
          <br className="mobile-break" />
          industry-leading SLA. It would be
          <br className="mobile-break" />
          <br className="desktop-break" />
          unlikely to experience video outage&nbsp;
          <br className="mobile-break" />
          without first being alerted device
          <br className="mobile-break" />
          <br className="desktop-break" />
          outage. Flexibility does not only&nbsp;
          <br className="mobile-break" />
          come from the structure of our cloud
          <br className="mobile-break" />
          <br className="desktop-break" />
          services but also the way of&nbsp;
          <br className="mobile-break" />
          business.
        </div>
      </div>
      <div className="services-section">
        <Service
          description="CliO® is a video security service platform that records surveillance videos & IoT sensor telemetry and provides incident/ device management capabilities."
          downloadPath={ClioBrochureEn}
          features={clioFeatures}
          fileName="CliO_brochure"
          serviceImgUrl={homePage.cliOMockup.img}
          serviceName="Next Generation Video Security"
          serviceNameColor="red"
          serviceNumber="1"
        />
        <Service
          description="Faceta® provides realtime notifications on VIP/blocklist events, face database synchronizations, device health monitoring, event search/filtering/ management, incident history exports and user management."
          downloadPath={FacetaBrochureEn}
          features={facetaFeatures}
          fileName="Faceta_brochure"
          serviceImgUrl={homePage.imgFaceta.img}
          serviceName="Face Recognition Reimagined."
          serviceNameColor="blue"
          serviceNumber="2"
        />
      </div>
      <div className="serverless-section">
        <ArisanTitle
          className="home"
          color="white"
          title="SERVERLESS"
        />
        <div className="serverless-section__content-container">
          <div className="serverless-section__introduction-container">
            <p className="serverless-section__title arisan-h1">
              No Server Needed.
            </p>
            <p className="serverless-section__description">
              All Arisan’s services follow the&nbsp;
              <br className="mobile-break" />
              “Serverless” doctrine. By using&nbsp;
              <br className="desktop-break" />
              our&nbsp;
              <br className="mobile-break" />
              serverless technology stack, you would&nbsp;
              <br className="mobile-break" />
              be getting services&nbsp;
              <br className="desktop-break" />
              that is ready to use&nbsp;
              <br className="mobile-break" />
              with low maintenance and yet very&nbsp;
              <br className="mobile-break" />
              flexible&nbsp;
              <br className="desktop-break" />
              to fit variety of project demands.&nbsp;
              <br className="mobile-break" />
              <br className="mobile-break" />
              <br className="desktop-break" />
              <br className="desktop-break" />
              Through our user interface, services can&nbsp;
              <br className="mobile-break" />
              be activated within&nbsp;
              <br className="desktop-break" />
              minutes and there is&nbsp;
              <br className="mobile-break" />
              no server to procure, maintenance or&nbsp;
              <br className="mobile-break" />
              <br className="desktop-break" />
              otherwise manage. Eliminating the need&nbsp;
              <br className="mobile-break" />
              for servers, you can&nbsp;
              <br className="desktop-break" />
              now deliver&nbsp;
              <br className="mobile-break" />
              technologies to places where were not&nbsp;
              <br className="mobile-break" />
              feasible.
            </p>
          </div>
          <div className="serverless-section__images-container">
            <div className="serverless-section__server-image">
              <img
                src={homePage.server.icon}
                className="serverless-introduction__serverless-picture"
                alt=""
              />
            </div>
            <div className="serverless-section__cross-image">
              <img src={homePage.redCross.icon} alt="" />
              <div className="serverless-section__cross-text arisan-h1">SERVER</div>
            </div>
          </div>
        </div>
        <div className="serverless-section__icon-container">
          {icons.map((icon) => (
            <Icon icon={icon} key={icon.name} />
          ))}
        </div>
      </div>
    </>
  );
};

export default HomeContent;
