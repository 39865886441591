// region 1. Platform Libraries
import React from 'react';
import { useLocation } from 'react-router-dom';
// endregion

// region 2. Project Libraries
import AboutUsBlockHeader from './AboutUsBlockHeader';
import ServicesBlockHeader from './ServicesBlockHeader';
import ImageHeader from './ImageHeader';
// endregion

// region U. UI Markups
import './Header.scss';
// endregion

const Header = () => {
  const { pathname } = useLocation();

  const shouldRender = () => {
    switch (pathname.split('/')[1]) {
      case 'about-us':
        return <AboutUsBlockHeader />;
      case 'clio':
        return <ImageHeader page="clio" />;
      case 'faceta':
        return <ImageHeader page="faceta" />;
      case 'peer':
        return <ImageHeader page="peer" />;
      default:
        return <ServicesBlockHeader />;
    }
  };

  return (
    <header className="header">
      {shouldRender()}
    </header>
  );
};

export default Header;
