// region 1. Platform Libraries
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  animateScroll as scroll, Element, Events, scroller,
} from 'react-scroll';
// endregion

// region 2. Project Libraries
import ClioBrochureEn from '../../assets/downloads/CliO_Brochure_En_180910.pdf';
import Carousel from '../Carousel';
import { ArisanTitle, PdfDownload } from '../Utils';
// endregion

// region U. UI Markups
import { clioPage } from '../../assets/images';
import './ClioContent.scss';
// endregion

const RenderOverview = () => {
  const overviewFeatures = [
    {
      icon: clioPage.deviceHealthMonitoring.icon,
      text: 'Device Health Monitoring',
    }, {
      icon: clioPage.incidentManagement.icon,
      text: 'Incident Management',
    }, {
      icon: clioPage.liveBroadcasting.icon,
      text: 'Live Broadcasting',
    }, {
      icon: clioPage.videoArchive.icon,
      text: 'Video Archive',
    },
  ];
  return (
    <div className="overview">
      {
        overviewFeatures.map((feat) => (
          <div className="overview__item" key={feat.text}>
            <div className="overview__item--icon">
              <img src={feat.icon} alt="" />
            </div>
            <span className="overview__item--text arisan-h3">{feat.text}</span>
          </div>
        ))
      }
    </div>
  );
};

const SectionFeature = ({
  children, iconLeft, images, number, title,
}) => (
  <div className={`section-features ${iconLeft ? 'left' : ''}`}>
    <div className="section-features__icon section-features__icon--box">
      <div className="section-features__icon--box">
        <img src={images} alt="" />
      </div>
    </div>
    <div className="section-features__content-container">
      <div className="section-features__number">
        <img src={number} alt="" />
      </div>
      <div className="section-features__content-box">
        <div className="section-features__content-box--title arisan-h2">
          {title}
        </div>
        <div className="section-features__content-box--content">
          {children}
        </div>
      </div>
    </div>
  </div>
);

const slides = [
  {
    image: clioPage.fullCompatibility.icon,
    number: clioPage.fullCompatibility.number,
    name: 'Full Compatibility',
    description: (
      <>
        CliO is compatible with variety of&nbsp;
        <br className="mobile-break" />
        cameras, recorders and&nbsp;
        <br className="desktop-break" />
        sensors. Just&nbsp;
        <br className="mobile-break" />
        bring your own device. CliO is designed&nbsp;
        <br className="mobile-break" />
        to be&nbsp;
        <br className="desktop-break" />
        compatible with plethora of&nbsp;
        <br className="mobile-break" />
        surveillance equipments using&nbsp;
        <br className="desktop-break" />
        standard&nbsp;
        <br className="mobile-break" />
        protocols. You don’t have to replace all&nbsp;
        <br className="mobile-break" />
        your cameras.
      </>),
  },
  {
    image: clioPage.noMaintenance.icon,
    number: clioPage.noMaintenance.number,
    name: 'No Maintenance',
    description: (
      <>
        Compared to hosting your own&nbsp;
        <br className="mobile-break" />
        conventional video surveillance&nbsp;
        <br className="desktop-break" />
        data&nbsp;
        <br className="mobile-break" />
        center, CliO greatly reduces installation&nbsp;
        <br className="mobile-break" />
        logistics where&nbsp;
        <br className="desktop-break" />
        projects can be&nbsp;
        <br className="mobile-break" />
        kickstarted in a matter of minutes. Say&nbsp;
        <br className="mobile-break" />
        goodbye&nbsp;
        <br className="desktop-break" />
        to long sessions of planning,&nbsp;
        <br className="mobile-break" />
        procurement and logistics for&nbsp;
        <br className="mobile-break" />
        the&nbsp;
        <br className="desktop-break" />
        traditional colocation scenarios.
      </>
    ),
  },
  {
    image: clioPage.centralManagement.icon,
    number: clioPage.centralManagement.number,
    name: 'Central Management',
    description: (
      <>
        With just a laptop computer or a smart&nbsp;
        <br className="mobile-break" />
        phone, you are now able&nbsp;
        <br className="desktop-break" />
        to access all the&nbsp;
        <br className="mobile-break" />
        cameras and recordings for thousands of&nbsp;
        <br className="mobile-break" />
        your&nbsp;
        <br className="desktop-break" />
        serviced locations.
      </>
    ),
  },
  {
    image: clioPage.instantDeployment.icon,
    number: clioPage.instantDeployment.number,
    name: 'Instant Deployment',
    description: (
      <>
        With cloud computing, the power of CliO&nbsp;
        <br className="mobile-break" />
        is only a click away.&nbsp;
        <br className="desktop-break" />
        Deploy from scratch,&nbsp;
        <br className="mobile-break" />
        activate new features, scale up your&nbsp;
        <br className="mobile-break" />
        device&nbsp;
        <br className="desktop-break" />
        fleet. All in a matter of minutes.&nbsp;
        <br className="mobile-break" />
        Features are easily activated&nbsp;
        <br className="desktop-break" />
        and are&nbsp;
        <br className="mobile-break" />
        always ready to use.
      </>
    ),
  },
  {
    image: clioPage.solidSecurity.icon,
    number: clioPage.solidSecurity.number,
    name: 'Solid Security',
    description: (
      <>
        Onsite equipment is susceptible to&nbsp;
        <br className="mobile-break" />
        variety of risks, e.g. fire,&nbsp;
        <br className="desktop-break" />
        theft, damage. CliO’s secure offsite cloud data&nbsp;
        <br className="mobile-break" />
        warehousing&nbsp;
        <br className="desktop-break" />
        offers far more rigidity for data rested within.
        <p>
          * Security features are powered by Microsoft Azure&nbsp;
          <br className="mobile-break" />
          data centers.
        </p>
      </>
    ),
  },
  {
    image: clioPage.highAvailability.icon,
    number: clioPage.highAvailability.number,
    name: 'High Availability',
    description: (
      <>
        CliO provides best-in-class availability&nbsp;
        <br className="mobile-break" />
        backed by industry-
        <br className="desktop-break" />
        leading service level&nbsp;
        <br className="mobile-break" />
        agreement.
      </>
    ),
  },
  {
    image: clioPage.designedBy.icon,
    number: clioPage.designedBy.number,
    name: `
      Designed by
      Security Professionals
    `,
    description: (
      <>
        Video surveillance and data streaming&nbsp;
        <br className="mobile-break" />
        are reimagined and&nbsp;
        <br className="desktop-break" />
        user experience is&nbsp;
        <br className="mobile-break" />
        implemented from scratch. CliO is&nbsp;
        <br className="mobile-break" />
        designed&nbsp;
        <br className="desktop-break" />
        by security professionals for&nbsp;
        <br className="mobile-break" />
        security professional.
      </>
    ),
  },
];

const ClioContent = () => {
  useEffect(() => {
    Events.scrollEvent.register('begin');
    Events.scrollEvent.register('end');
    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  const { pathname } = useLocation();
  useEffect(() => {
    const directTo = pathname.replace('/clio/', '');

    if (directTo !== '/clio') {
      window.scrollTo(0, 0);
      setTimeout(() => { /* Need to wait for load the image */
        scroller.scrollTo(directTo, {
          delay: 0,
          duration: 800,
          offset: 0,
          smooth: true,
        });
      }, 800);
    } else {
      setTimeout(() => {
        scroll.scrollToTop();
      }, 800);
    }
  }, [pathname]);

  return (
    <>
      <div className="what-is-clio">
        <div className="what-is-clio__content">
          <div className="what-is-clio__content-box">
            <div className="what-is-clio__content-box--title arisan-h1">
              What is CliO?
            </div>
            <div className="what-is-clio__content-box--content">
              CliO® is a video security service platform&nbsp;
              <br className="mobile-break" />
              that records&nbsp;
              <br className="desktop-break" />
              surveillance videos &amp; IoT&nbsp;
              <br className="mobile-break" />
              sensor telemetry and provides&nbsp;
              <br className="desktop-break" />
              incident/&nbsp;
              <br className="mobile-break" />
              device management capabilities.
              <br />
              <br />
              CliO aggregates and organizes massive&nbsp;
              <br className="mobile-break" />
              amount of data&nbsp;
              <br className="desktop-break" />
              from all major brands of&nbsp;
              <br className="mobile-break" />
              surveillance/IoT equipment in an&nbsp;
              <br className="desktop-break" />
              orderly&nbsp;
              <br className="mobile-break" />
              fashion every single second.
              <br />
              <br />
              Since launch, CliO has processed more&nbsp;
              <br className="mobile-break" />
              than 10 centuries&nbsp;
              <br className="desktop-break" />
              worth of video data.&nbsp;
              <br className="mobile-break" />
              CliO has been adopted in retail,
              <br className="mobile-break" />
              <br className="desktop-break" />
              security, or defense projects around the&nbsp;
              <br className="mobile-break" />
              world.
            </div>
          </div>
          <div className="what-is-clio__image-box">
            <div className="what-is-clio__image-box--image">
              <img src={clioPage.whatIsClioImages} alt="" />
            </div>
            <div className="what-is-clio__image-box--text arisan-footnote">
              [  IP/Analog surveillance Cameras  +  IoT Sensors  ]
            </div>
          </div>
        </div>
      </div>
      <div className="how-it-works">
        <div className="how-it-works__title">
          <ArisanTitle title="HOW IT WORKS" />
        </div>
        <div className="how-it-works__content">
          <picture>
            <source
              media="(max-width: 1365px)"
              srcSet={clioPage.howItWorksIllustration.mobile}
            />
            <img
              src={clioPage.howItWorksIllustration.desktop}
              alt=""
            />
          </picture>
        </div>
      </div>
      <div className="key-features-1">
        <div className="key-features-1__title">
          <ArisanTitle title="KEY FEATURES" color="white" />
        </div>
        <div className="key-features-1__overview">
          <RenderOverview />
        </div>
        <div className="key-features-1__monitor">
          <Element id="device-health-monitoring" name="device-health-monitoring" className="element-position" />
          <div className="health-monitoring">
            <div className="health-monitoring__feature">
              <div className="health-monitoring__feature--icon">
                <img src={clioPage.deviceHealthMonitoring.icon} alt="" />
              </div>
              <div className="health-monitoring__feature--title arisan-h2">
                Device Health Monitoring
              </div>
              <div className="health-monitoring__feature--slogan arisan-h1">
                Healthy Devices Happy Customers
              </div>
              <div className="health-monitoring__feature--description">
                Before any incident, you can be in full&nbsp;
                <br className="mobile-break" />
                grasp of the&nbsp;
                <br className="desktop-break" />
                current status of all your&nbsp;
                <br className="mobile-break" />
                remote devices and take&nbsp;
                <br className="desktop-break" />
                preemptive&nbsp;
                <br className="mobile-break" />
                measures to resolve device failure with
                <br className="desktop-break" />
                <br className="mobile-break" />
                help of CliO.
              </div>
            </div>
            <div className="health-monitoring__impression">
              <div className="health-monitoring__impression--image">
                <img src={clioPage.deviceHealthMonitoring.impression} alt="" />
              </div>
              <div className="health-monitoring__impression--line">
                <picture>
                  <source
                    media="(max-width: 1365px)"
                    srcSet={clioPage.deviceHealthMonitoring.line.mobile}
                  />
                  <img src={clioPage.deviceHealthMonitoring.line.desktop} alt="" />
                </picture>
              </div>
            </div>
          </div>
        </div>
        <div className="key-features-1__incident-management">
          <Element id="incident-management" name="incident-management" className="element-position" />
          <div className="incident-management">
            <div className="incident-management__feature">
              <div className="incident-management__feature--icon">
                <img src={clioPage.incidentManagement.icon} alt="" />
              </div>
              <div className="incident-management__feature--title arisan-h2">
                Incident Management
              </div>
              <div className="incident-management__feature--slogan arisan-h1">
                {`
                Details & Overview,
                Just in Time
              `}
              </div>
              <div className="incident-management__feature--description">
                In one central portal, CliO collect&nbsp;
                <br className="mobile-break" />
                events from&nbsp;
                <br className="desktop-break" />
                remote devices and allows&nbsp;
                <br className="mobile-break" />
                event searches or&nbsp;
                <br className="desktop-break" />
                notifications of&nbsp;
                <br className="mobile-break" />
                important events. By utilizing both
                <br className="mobile-break" />
                <br className="desktop-break" />
                built-in event processing pipeline&nbsp;
                <br className="mobile-break" />
                interface for&nbsp;
                <br className="desktop-break" />
                incident response and an&nbsp;
                <br className="mobile-break" />
                open RESTful API for fast&nbsp;
                <br className="desktop-break" />
                and easy&nbsp;
                <br className="mobile-break" />
                integration with your existing
                <br className="mobile-break" />
                <br className="desktop-break" />
                infrastructure.
              </div>
              <div className="incident-management__feature--red-line" />
              <div className="incident-management__feature--type-title arisan-h3">
                Incident Types
              </div>
              <div className="incident-management__feature--type-category arisan-footnote">
                <p> •  ALARM TRIGGERED</p>
                <p> •  BATTERY LOW</p>
                <p> •  BLACKLISTEE DETECTED</p>
                <p> •  CONNECTION LOST</p>
                <p> •  CONNECTION RESTORED</p>
                <p> •  HUMAN DETECTED</p>
                <p> •  INTRUSION DETECTED</p>
                <p> •  MOTION DETECTED</p>
                <p> •  STRANGER DETECTED</p>
                <p> •  TELEMETER ARMED</p>
                <p> •  TELEMETER DISARMED</p>
                <p> •  VIDEO LOST</p>
                <p> •  VIOLENCE DETECTED</p>
                <p> •  WHITELISTEE DETECTED</p>
              </div>
            </div>
            <div className="incident-management__impression">
              <picture>
                <source
                  media="(max-width: 1365px)"
                  srcSet={clioPage.incidentManagement.impression.mobile}
                />
                <img src={clioPage.incidentManagement.impression.desktop} alt="" />
              </picture>
            </div>
          </div>
        </div>
        <div className="key-features-1__live-broadcasting">
          <Element id="live-broadcasting" name="live-broadcasting" className="element-position" />
          <div className="live-broadcasting">
            <div className="live-broadcasting__feature">
              <div className="live-broadcasting__feature--icon">
                <img src={clioPage.liveBroadcasting.icon} alt="" />
              </div>
              <div className="live-broadcasting__feature--title arisan-h2">
                Live Broadcasting
              </div>
              <div className="live-broadcasting__feature--slogan arisan-h1">
                Seize the Moment
              </div>
              <div className="live-broadcasting__feature--description">
                CliO works with HTTP Live Streaming,&nbsp;
                <br className="mobile-break" />
                the most&nbsp;
                <br className="desktop-break" />
                popular media streaming&nbsp;
                <br className="mobile-break" />
                communication protocol&nbsp;
                <br className="desktop-break" />
                and turns any&nbsp;
                <br className="mobile-break" />
                video surveillance camera into live
                <br className="mobile-break" />
                <br className="desktop-break" />
                broasting video source for both security&nbsp;
                <br className="mobile-break" />
                and&nbsp;
                <br className="desktop-break" />
                marketing needs.
              </div>
            </div>
            <div className="live-broadcasting__impression">
              <img src={clioPage.liveBroadcasting.impression} alt="" />
            </div>
            <div className="live-broadcasting__x1000">
              <picture>
                <source
                  media="(max-width: 1365px)"
                  srcSet={clioPage.liveBroadcasting.x1000.mobile}
                />
                <img src={clioPage.liveBroadcasting.x1000.desktop} alt="" />
              </picture>
            </div>
          </div>
        </div>
        <div className="key-features-1__video-archive">
          <Element id="video-archive" name="video-archive" className="element-position" />
          <div className="video-archive">
            <div className="video-archive__impression">
              <img src={clioPage.videoArchive.impression} alt="" />
            </div>
            <div className="video-archive__feature">
              <div className="video-archive__feature--icon">
                <img src={clioPage.videoArchive.icon} alt="" />
              </div>
              <div className="video-archive__feature--title arisan-h2">
                Video Archive
              </div>
              <div className="video-archive__feature--slogan arisan-h1">
                {`
                Secure, Reliable 
                & Efficient
             `}
              </div>
              <div className="video-archive__feature--description">
                Embracing native cloud technologies and&nbsp;
                <br className="mobile-break" />
                designed&nbsp;
                <br className="desktop-break" />
                with scalability,&nbsp;
                <br className="mobile-break" />
                availability &amp; reliability in mind,&nbsp;
                <br className="desktop-break" />
                CliO&nbsp;
                <br className="mobile-break" />
                provides secure &amp; stable video recording&nbsp;
                <br className="mobile-break" />
                and&nbsp;
                <br className="desktop-break" />
                warehousing. You may activate your&nbsp;
                <br className="mobile-break" />
                video&nbsp;
                <br className="desktop-break" />
                recording service instantly.
              </div>
              <div className="video-archive__feature--format arisan-footnote">
                <p>• Compatible with H.264/MPEG-4 AVC video compression standard</p>
                <p>• Partially supports H.265/HEVC.</p>
                <p>• Each video stream can go up to 4K resolutions.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="key-features-1__built-in-services">
          <div className="built-in">
            <div className="built-in__title arisan-h2">
              CliO’s Built-in Services
            </div>
            <div className="built-in__container">
              <div className="built-in__container--image">
                <picture>
                  <source
                    media="(max-width: 1365px)"
                    srcSet={clioPage.builtIn.icons.mobile}
                  />
                  <img src={clioPage.builtIn.icons.desktop} alt="" />
                </picture>
              </div>
              <div className="built-in__container--text arisan-h3">
                <span>Load Balancing</span>
                <span>Auto-recovery</span>
                <span>Circuit Breake</span>
                <span>Encryptions</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="key-features-2">
        <div className="key-features-2__title">
          <ArisanTitle title="KEY FEATURES" />
        </div>
        <Carousel
          hasControl={false}
          hasIndicator
          isKeyFeatures
          slides={slides}
        />
        <div className="key-features-2__content-box">
          <SectionFeature title={slides[0].name} images={slides[0].image} number={slides[0].number}>
            {slides[0].description}
          </SectionFeature>
          <SectionFeature
            title={slides[1].name}
            images={slides[1].image}
            number={slides[1].number}
            iconLeft
          >
            {slides[1].description}
          </SectionFeature>
        </div>
        <div className="key-features-2__image-box">
          <div className="key-features-2__imag-box--image">
            <img src={clioPage.keyFeatures.constructionSites} alt="" />
          </div>
        </div>
        <div className="key-features-2__content-box">
          <SectionFeature title={slides[2].name} images={slides[2].image} number={slides[2].number}>
            {slides[2].description}
          </SectionFeature>
          <SectionFeature
            title={slides[3].name}
            images={slides[3].image}
            number={slides[3].number}
            iconLeft
          >
            {slides[3].description}
          </SectionFeature>
        </div>
        <div className="key-features-2__image-box">
          <div className="key-features-2__imag-box--image">
            <img src={clioPage.keyFeatures.supermarkets} alt="" />
          </div>
        </div>
        <div className="key-features-2__content-box">
          <SectionFeature title={slides[4].name} images={slides[4].image} number={slides[4].number}>
            {slides[4].description}
          </SectionFeature>
          <SectionFeature
            title={slides[5].name}
            images={slides[5].image}
            number={slides[5].number}
            iconLeft
          >
            {slides[5].description}
          </SectionFeature>
          <SectionFeature title={slides[6].name} images={slides[6].image} number={slides[6].number}>
            {slides[6].description}
          </SectionFeature>
        </div>
        <div className="key-features-2__image-box">
          <div className="key-features-2__imag-box--image">
            <img src={clioPage.keyFeatures.convenientStores} alt="" />
          </div>
        </div>
      </div>
      <div className="download">
        <PdfDownload file={ClioBrochureEn} productName="CliO" />
      </div>
    </>
  );
};

SectionFeature.propTypes = {
  children: PropTypes.element.isRequired,
  iconLeft: PropTypes.bool,
  images: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

SectionFeature.defaultProps = {
  iconLeft: false,
};

export default ClioContent;
