// region 1. Platform Libraries
import PropTypes from 'prop-types';
import React from 'react';
// endregion

// region 2. Project Libraries
import './Problem.scss';
// endregion

const Problem = ({
  imageUrl,
  description,
  highlightText,
  solutionImageUrl,
  solutionName,
}) => {
  const normalTextPieces = description.split(highlightText);
  const frontPart = normalTextPieces[0] ? normalTextPieces[0].trim().split(' ') : null;
  const backPrat = normalTextPieces[1] ? normalTextPieces[1].split(' ') : null;

  return (
    <div className="problem">
      <div className="problem__image-container">
        <img src={imageUrl} alt="" />
      </div>
      <div className="problem__text-container">
        <div className="problem__description">
          <span className="description__normal arisan-h2-normal">
            &quot;&nbsp;
          </span>
          {frontPart && frontPart.map((word) => (
            <span className="description__normal arisan-h2-normal" key={word}>
              {word}
              &nbsp;
            </span>
          ))}
          <span className="description__highlight arisan-h2">
            <div className="description__strikethrough" />
            {highlightText}
          </span>
          {backPrat && backPrat.map((word) => (
            <span className="description__normal arisan-h2-normal" key={word}>
              {word}
              &nbsp;
            </span>
          ))}
          <span className="description__normal arisan-h2-normal">
            &quot;
          </span>
        </div>
        <div className="problem__solution">
          <div className="problem__solution-image">
            <img src={solutionImageUrl} alt="" />
          </div>
          <div className="problem__solution-name">
            {solutionName}
          </div>
        </div>
      </div>
    </div>
  );
};

Problem.propTypes = {
  description: PropTypes.string.isRequired,
  highlightText: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  solutionImageUrl: PropTypes.string.isRequired,
  solutionName: PropTypes.string.isRequired,
};

export default Problem;
