// region 1. Platform Libraries
import React from 'react';
import { useLocation } from 'react-router-dom';
// endregion

// region 2. Project Libraries
import AboutUsBackground from './AboutUsBackground';
import BottomBackground from './BottomBackground';
import BottomFooterBackground from './BottomFooterBackground';
import ClioBackground from './ClioBackground';
import FacetaBackground from './FacetaBackground';
import HomeBackground from './HomeBackground';
import PeerBackground from './PeerBackground';
import ServicesBackground from './ServicesBackground';
// endregion

// region U. UI Markups
import './Background.scss';
// endregion

const Background = () => {
  const { pathname } = useLocation();
  const shouldRender = () => {
    switch (pathname.split('/')[1]) {
      case 'about-us':
        return <AboutUsBackground />;
      case 'clio':
        return <ClioBackground />;
      case 'custom-solutions':
        return <ServicesBackground />;
      case 'faceta':
        return <FacetaBackground />;
      case 'peer':
        return <PeerBackground />;
      case 'services':
        return <ServicesBackground />;
      default:
        return <HomeBackground />;
    }
  };
  const bottomBackground = () => {
    switch (pathname.split('/')[1]) {
      case '':
        return <BottomBackground isHome />;
      case 'about-us':
        return <BottomFooterBackground />;
      case 'custom-solutions':
      case 'services':
        return <BottomBackground isServices />;
      case 'peer':
        return <BottomBackground isPeer />;
      default:
        return <BottomBackground />;
    }
  };

  return (
    <div className="background">
      {shouldRender()}
      {bottomBackground()}
    </div>
  );
};

export default Background;
