// region 1. Platform Libraries
import React from 'react';
// endregion

// region U. UI Markups
import './AboutUsBackground.scss';
// endregion

const AboutUsBackground = () => (
  <div className="about-us-background">
    <div className="about-us-background__header" />
    <div className="about-us-background__mission" />
    <div className="about-us-background__team">
      <div className="about-us-background__team--triangle" />
      <div className="about-us-background__team--bottom" />
    </div>
  </div>
);

export default AboutUsBackground;
