// region 1. Platform Libraries
import React from 'react';
// endregion

// region U. UI Markups
import { facetaPage } from '../../assets/images';
// endregion

const {
  accessControl, blocklist, faceStatistics, vip,
} = facetaPage;

const applicationsData = {
  accessControl: {
    icon: accessControl.icon,
    number: accessControl.number,
    title: 'Access Control',
    content: (
      <>
        • Open API allows seamless&nbsp;
        <br className="mobile-break" />
        integration with existing&nbsp;
        <br className="desktop-break" />
        access&nbsp;
        <br className="mobile-break" />
        control system.
        <br />
        <br />
        • Faceta sends realtime&nbsp;
        <br className="mobile-break" />
        notifications upon intrusions.
        <br />
        <br />
        • Event management is made easy&nbsp;
        <br className="mobile-break" />
        with history search
        <br />
        /filtering/export functions.
      </>
    ),
    locationsData: [
      {
        icon: accessControl.locationsIcons.corporateFactory,
        title: 'Corporate / Factory',
        content: (
          <>
            Faceta&apos;s attendance&nbsp;
            <br className="mobile-break" />
            management works
            <br />
            in tandem with existing&nbsp;
            <br className="mobile-break" />
            systems.
          </>
        ),
      }, {
        icon: accessControl.locationsIcons.residential,
        title: 'Residential',
        content: (
          <>
            Faceta strengthens security&nbsp;
            <br className="mobile-break" />
            with another&nbsp;
            <br className="desktop-break" />
            layer of access&nbsp;
            <br className="mobile-break" />
            control.
          </>
        ),
      }, {
        icon: accessControl.locationsIcons.school,
        title: 'School',
        content: (
          <>
            Student attendance can now&nbsp;
            <br className="mobile-break" />
            be taken&nbsp;
            <br className="desktop-break" />
            automatically.
          </>
        ),
      }, {
        icon: accessControl.locationsIcons.dataCenter,
        title: 'Data Center',
        content: (
          <>
            Faceta provides additional&nbsp;
            <br className="mobile-break" />
            biometric&nbsp;
            <br className="desktop-break" />
            access control on&nbsp;
            <br className="mobile-break" />
            top of existing security.
          </>
        ),
      },
    ],
  },
  blocklist: {
    icon: blocklist.icon,
    number: blocklist.number,
    title: 'Blocklist',
    content: (
      <>
        • Realtime notifications on both&nbsp;
        <br className="mobile-break" />
        mobile devices,&nbsp;
        <br className="desktop-break" />
        desktops and I/O&nbsp;
        <br className="mobile-break" />
        blocks allows faster response to
        <br />
        incidents in many scenarios.
        <br />
        <br />
        • Presence of blocklisted subjects&nbsp;
        <br className="mobile-break" />
        can be searched,&nbsp;
        <br className="desktop-break" />
        exported or&nbsp;
        <br className="mobile-break" />
        otherwise managed intuitively on&nbsp;
        <br className="mobile-break" />
        Faceta&nbsp;
        <br className="desktop-break" />
        apps.
      </>
    ),
    locationsData: [
      {
        icon: blocklist.locationsIcons.supermarket,
        title: 'Supermarket',
        content: (
          <>
            Blocklisted person triggers&nbsp;
            <br className="mobile-break" />
            staff&apos;s&nbsp;
            <br className="desktop-break" />
            response procedures.
          </>
        ),
      },
      {
        icon: blocklist.locationsIcons.residential,
        title: 'Residential',
        content: (
          <>
            Faceta expedites response&nbsp;
            <br className="mobile-break" />
            from security&nbsp;
            <br className="desktop-break" />
            guards to&nbsp;
            <br className="mobile-break" />
            blocklisted person.
          </>
        ),
      },
    ],
  },
  faceStatistics: {
    icon: faceStatistics.icon,
    number: faceStatistics.number,
    title: 'Face Statistics',
    content: (
      <>
        • Simply keeping track of visitor count&nbsp;
        <br className="mobile-break" />
        opens up doorways&nbsp;
        <br className="desktop-break" />
        to greater&nbsp;
        <br className="mobile-break" />
        business insight.
        <br />
        <br />
        • With Faceta data API, correlation&nbsp;
        <br className="mobile-break" />
        between customer&nbsp;
        <br className="desktop-break" />
        prefences and&nbsp;
        <br className="mobile-break" />
        age/gender data can be easily&nbsp;
        <br className="mobile-break" />
        computed&nbsp;
        <br className="desktop-break" />
        and allows further&nbsp;
        <br className="mobile-break" />
        analysis.
      </>
    ),
    locationsData: [
      {
        icon: faceStatistics.locationsIcons.retail,
        title: 'Retail',
        content: (
          <>
            Storefronts can use Faceta&nbsp;
            <br className="mobile-break" />
            data to determine best
            <br />
            promotion campaign for&nbsp;
            <br className="mobile-break" />
            targeted demographics.
          </>
        ),
      },
    ],
  },
  vip: {
    icon: vip.icon,
    number: vip.number,
    title: 'VIP',
    content: (
      <>
        • Realtime notifications on both&nbsp;
        <br className="mobile-break" />
        mobile devices,&nbsp;
        <br className="desktop-break" />
        desktops and I/O&nbsp;
        <br className="mobile-break" />
        blocks allows triggering immediate
        <br />
        human and automatic response to&nbsp;
        <br className="mobile-break" />
        VIP&apos;s presence.
        <br />
        <br />
        • VIP appearance history can be&nbsp;
        <br className="mobile-break" />
        searched, exported or&nbsp;
        <br className="desktop-break" />
        otherwise&nbsp;
        <br className="mobile-break" />
        managed intuitively on Faceta apps.
      </>
    ),
    locationsData: [
      {
        icon: vip.locationsIcons.corporateFactory,
        title: 'Corporate / Factory',
        content: (
          <>
            Faceta notifies instantly&nbsp;
            <br className="mobile-break" />
            upon&nbsp;
            <br className="desktop-break" />
            arrival of important&nbsp;
            <br className="mobile-break" />
            clients.
          </>
        ),
      }, {
        icon: vip.locationsIcons.shops,
        title: 'Shops',
        content: (
          <>
            Faceta reminds you VIP&apos;s
            shopping preferences.
          </>
        ),
      }, {
        icon: vip.locationsIcons.school,
        title: 'School',
        content: (
          <>
            Parents get visual&nbsp;
            <br className="mobile-break" />
            confirmation from&nbsp;
            <br className="desktop-break" />
            Faceta&nbsp;
            <br className="mobile-break" />
            on their kids&apos; safe arrival.
          </>
        ),
      },
    ],
  },
};

export { applicationsData as default };
