// region 1. Platform Libraries
import React from 'react';
// endregion

// region 2. Project Libraries
import HomeContent from '../../components/HomeContent';
import ArisanInfo from '../../components/ArisanInfo';
// endregion

// region U. UI Markups
import './Home.scss';
// endregion

const Home = () => (
  <div className="home">
    <HomeContent />
    <ArisanInfo showApplication />
  </div>
);

export default Home;
