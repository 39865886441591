// region 1. Platform Libraries
import PropTypes from 'prop-types';
import React from 'react';
// endregion

// region U. UI Markups
import images from '../../assets/images';
import Carousel from '../Carousel';
import ArisanTitle from '../Utils/ArisanTitle';
import './Cases.scss';
// endregion

const slides = [
  {
    name: 'Liang-Fu',
    image: images.cases.liangFu,
    type: 'Security Services',
  },
  {
    name: 'WorldTrend Co., Ltd',
    image: images.cases.worldTrend,
    type: 'Security Services',
  },
  {
    name: 'FamilyMart',
    image: images.cases.familyMart,
    type: 'Convenient Store',
  },
];

const Cases = ({ isServices }) => {
  const createCustomer = (customer) => (
    <div className="cases-section__case-container" key={customer.name}>
      <div className="cases-section__image">
        <img src={customer.image} alt="" />
      </div>
      <div className="cases-section__information">
        <div className="cases-section__name arisan-h3-normal">{customer.name}</div>
        <div className="cases-section__type">{customer.type}</div>
      </div>
    </div>
  );
  return (
    <div className={`cases-section ${isServices ? 'is-services' : ''}`}>
      <div className="cases-section__title">
        <ArisanTitle title="CASES" className="home" />
      </div>
      <Carousel
        hasControl={false}
        hasIndicator
        slides={slides}
      />
      <div className="cases-section__content">
        {slides.map((slide) => (createCustomer(slide)))}
      </div>
    </div>
  );
};

Cases.propTypes = {
  isServices: PropTypes.bool,
};

Cases.defaultProps = {
  isServices: false,
};

export default Cases;
