// region 1. Platform Libraries
import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
// endregion

// region U. UI Markups
import ArisanContactInformation from '../Utils/ArisanContactInformation';
import './Footer.scss';
// endregion

const Footer = () => (
  <footer className="footer">
    <div className="container">
      <nav className="container__site-map">
        <div className="page">
          <div className="page__name arisan-h3">SERVICES</div>
          <div className="page__internal-link">
            <Link className={(!!useRouteMatch('/clio') && 'active') || ''} to="/clio">
              CliO
            </Link>
            <Link className={(!!useRouteMatch('/faceta') && 'active') || ''} to="/faceta">
              Faceta
            </Link>
            <Link className={(!!useRouteMatch('/peer') && 'active') || ''} to="/peer">
              Peer
            </Link>
            <Link className={(!!useRouteMatch('/custom-solutions') && 'active') || ''} to="/custom-solutions">
              CUSTOM SOLUTIONS
            </Link>
          </div>
        </div>
        <div className="page disabled">
          <div className="page__name arisan-h3">CASES</div>
          <div className="page__internal-link">
            <a href="/#">APPLICATIONS</a>
            <a href="/#">CASES</a>
          </div>
        </div>
        <div className="page">
          <div className="page__name arisan-h3">ABOUT US</div>
          <div className="page__internal-link">
            <Link className={(!!useRouteMatch('/about-us/mission') && 'active') || ''} to="/about-us/mission">
              MISSION
            </Link>
            <Link className={(!!useRouteMatch('/about-us/team') && 'active') || ''} to="/about-us/team">
              TEAM
            </Link>
          </div>
        </div>
      </nav>
      <div className="container__company-information arisan-bold">
        <ArisanContactInformation />
      </div>
    </div>
  </footer>
);

export default Footer;
