// region 1. Platform Libraries
import PropTypes from 'prop-types';
import React from 'react';
// endregion

// region U. UI Markups
import './ApplicationIcon.scss';
// endregion

const ApplicationIcon = ({ icon }) => (
  <div className="application-icon-wrapper">
    <div className="application-icon-wrapper__image">
      <img src={icon.image} alt="" />
    </div>
    <div className="application-icon-wrapper__name arisan-h3-normal">
      {icon.name}
    </div>
  </div>
);

ApplicationIcon.propTypes = {
  icon: PropTypes.shape({
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default ApplicationIcon;
