// region 1. Platform Libraries
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
// endregion

// region 2. Project Libraries
import { homePage } from '../../assets/images';
import ArisanTitle from '../Utils/ArisanTitle';
import './Service.scss';
// endregion

const Service = ({
  serviceNumber,
  serviceImgUrl,
  serviceName,
  serviceNameColor,
  description,
  downloadPath,
  fileName,
  features,
}) => (
  <div className="service">
    <ArisanTitle className="home" title="SERVICES" />
    <ArisanTitle className="home" title={serviceNumber} />
    <div className="service__content-container">
      <div className="service__image">
        <img src={serviceImgUrl} alt="" />
      </div>
      <div className="service__information-container">
        <div className={`service__name service__name--${serviceNameColor}`}>
          {serviceName}
        </div>
        <div className="service__description">
          {description}
        </div>
        <div className="service__downloadButton">
          <a href={downloadPath} download={fileName} className="service__downloadButton-link">
            <span>Download Brochure</span>
            <img src={homePage.pdfDownload.icon} alt="" />
          </a>
        </div>
      </div>
    </div>
    <div className="service__features">
      {features.map((feature) => (
        <Link to={feature.path} key={feature.imageUrl} className="features__link">
          <div className="features__icon">
            <img src={feature.imageUrl} alt="" />
          </div>
          <div className={`features__name features__name--${serviceNameColor}`}>
            {feature.name}
          </div>
        </Link>
      ))}
    </div>
  </div>
);

Service.propTypes = {
  description: PropTypes.string.isRequired,
  downloadPath: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(PropTypes.shape({
    imageUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  })).isRequired,
  fileName: PropTypes.string.isRequired,
  serviceImgUrl: PropTypes.string.isRequired,
  serviceName: PropTypes.string.isRequired,
  serviceNameColor: PropTypes.string,
  serviceNumber: PropTypes.string.isRequired,
};

Service.defaultProps = {
  serviceNameColor: 'red',
};

export default Service;
