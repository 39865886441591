// region 1. Platform Libraries
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  animateScroll as scroll, Element, Events, scroller,
} from 'react-scroll';
// endregion

// region 2. Project Libraries
import FacetaBrochureEn from '../../assets/downloads/Faceta_Brochure_En_190416.pdf';
import { ArisanTitle, PdfDownload } from '../Utils';
import applicationsData from './applicationsData';
// endregion

// region U. UI Markups
import { facetaPage } from '../../assets/images';
import videos from '../../assets/videos';
import './FacetaContent.scss';
// endregion

const RenderOverview = () => {
  const overviewFeatures = [
    {
      icon: facetaPage.keyFeatures.easyInstallation,
      title: 'Easy Installation',
      content: (
        <>
          Install just the Faceta camera.
          <br />
          Software deployment on server
          <br />
          hardware is no longer needed.
        </>
      ),
    }, {
      icon: facetaPage.keyFeatures.easyIntergration,
      title: 'Easy Integration',
      content: (
        <>
          Open API allows integration
          <br />
          with existing access control
          <br />
          and CCTV systems.
        </>
      ),
    }, {
      icon: facetaPage.keyFeatures.easyManagement,
      title: 'Easy Management',
      content: (
        <>
          Plug and play with fully featured
          <br />
          security platform, CliO. Save
          <br />
          development resources.
        </>
      ),
    },
  ];

  return (
    <div className="overview">
      {
        overviewFeatures.map((feat) => {
          const [title1, title2] = feat.title.split(' ', 2);

          return (
            <div className="overview__item" key={title2}>
              <div className="overview__item--icon">
                <img src={feat.icon} alt="" />
              </div>
              <div className="overview__item--title arisan-h3">
                <span className="overview__item--title-normal">{`${title1} `}</span>
                <span className="overview__item--title-bold">{title2}</span>
              </div>
              <div className="overview__item--content">
                <p>{feat.content}</p>
              </div>
            </div>
          );
        })
      }
    </div>
  );
};

const ApplicationsAvailableLocations = ({ locationsData }) => (
  <>
    {locationsData.map((data) => {
      const { icon, title, content } = data;
      return (
        <div className="available-locations" key={title}>
          <div className="available-locations__container">
            <div className="available-locations__container--icon">
              <img src={icon} alt="" />
            </div>
            <div className="available-locations__content-box">
              <div className="available-locations__content-box--title arisan-h3">
                {title}
              </div>
              <div className="available-locations__content-box--content">
                {content}
              </div>
            </div>
          </div>
        </div>
      );
    })}
  </>
);

const ApplicationsFeature = ({ data }) => {
  const {
    content, icon, locationsData, number, title,
  } = data;

  const linkAnchorName = title.toLowerCase().replace(' ', '-');

  return (
    <div className="applications-features">
      <Element id={linkAnchorName} name={linkAnchorName} className="element-position" />
      <div className="applications-features__main-content">
        <div className="applications-features__icon-box">
          <div className="applications-features__icon-box--icon">
            <img src={icon} alt="" />
          </div>
          <div className="applications-features__icon-box--number">
            <img src={number} alt="" />
          </div>
        </div>
        <div className="applications-features__content-box">
          <div className="applications-features__content-box--title arisan-h2">
            {title}
          </div>
          <div className="applications-features__content-box--content arisan-h3">
            <p>
              {content}
            </p>
          </div>
        </div>
      </div>
      <div className="applications-features__available-locations-container">
        <ApplicationsAvailableLocations {...{ locationsData }} />
      </div>
    </div>
  );
};

const FacetaContent = () => {
  useEffect(() => {
    Events.scrollEvent.register('begin');
    Events.scrollEvent.register('end');
    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  const { pathname } = useLocation();
  useEffect(() => {
    const directTo = pathname.replace('/faceta/', '');

    if (directTo !== '/faceta') {
      window.scrollTo(0, 0);
      setTimeout(() => { /* Need to wait for load the image */
        scroller.scrollTo(directTo, {
          delay: 0,
          duration: 800,
          offset: 0,
          smooth: true,
        });
      }, 800);
    } else {
      setTimeout(() => {
        scroll.scrollToTop();
      }, 800);
    }
  }, [pathname]);

  return (
    <>
      <div className="what-is-faceta">
        <div className="what-is-faceta__content">
          <div className="what-is-faceta__content-box">
            <div className="what-is-faceta__content-box--title arisan-h1">
              What is Faceta?
            </div>
            <div className="what-is-faceta__content-box--content">
              Faceta® combines the power of deep&nbsp;
              <br className="mobile-break" />
              learning algorithm&nbsp;
              <br className="desktop-break" />
              and the performance&nbsp;
              <br className="mobile-break" />
              of Qualcomm chipset together in a
              <br className="mobile-break" />
              <br className="desktop-break" />
              single form factor, making traditional&nbsp;
              <br className="mobile-break" />
              face recognition&nbsp;
              <br className="desktop-break" />
              software server&nbsp;
              <br className="mobile-break" />
              obsolete. Up to 10,000 faces could be
              <br className="mobile-break" />
              <br className="desktop-break" />
              stored within just one camera and up to&nbsp;
              <br className="mobile-break" />
              10 faces could be&nbsp;
              <br className="desktop-break" />
              recognized&nbsp;
              <br className="mobile-break" />
              concurrently within 1.5 seconds.
              <br />
              <br />
              Coupled with CliO®, a serverless video&nbsp;
              <br className="mobile-break" />
              security platform,&nbsp;
              <br className="desktop-break" />
              Faceta provides&nbsp;
              <br className="mobile-break" />
              realtime notifications on VIP/blocklist
              <br />
              events, face database synchronizations,&nbsp;
              <br className="mobile-break" />
              device health&nbsp;
              <br className="desktop-break" />
              monitoring, event search/
              <br className="mobile-break" />
              filtering/management, incident
              <br className="mobile-break" />
              <br className="desktop-break" />
              history exports and user management.
            </div>
          </div>
          <div className="what-is-faceta__logo-box">
            <div className="what-is-faceta__logo-box--logo">
              <img src={facetaPage.logo} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="how-it-works">
        <div className="how-it-works__title">
          <ArisanTitle title="HOW IT WORKS" />
        </div>
        <div className="how-it-works__content">
          <video playsInline autoPlay muted loop>
            <source src={videos.facetaHowItWorks} type="video/mp4" />
          </video>
        </div>
      </div>
      <div className="key-features">
        <div className="key-features__title">
          <ArisanTitle title="KEY FEATURES" color="white" />
        </div>
        <div className="key-features__overview">
          <RenderOverview />
        </div>
      </div>
      <div className="applications">
        <div className="applications__title">
          <ArisanTitle title="APPLICATIONS" />
        </div>
        <div className="applications__content-box">
          <ApplicationsFeature data={applicationsData.accessControl} />
        </div>
        <div className="applications__images-box">
          <div className="applications__images-box--image image--left">
            <img src={facetaPage.applications.vip} alt="" />
          </div>
          <div className="applications__images-box--image image--right">
            <img src={facetaPage.applications.factory} alt="" />
          </div>
        </div>
        <div className="applications__content-box">
          <ApplicationsFeature data={applicationsData.vip} />
          <ApplicationsFeature data={applicationsData.blocklist} />
        </div>
        <div className="applications__images-box">
          <div className="applications__images-box--image image--right">
            <img src={facetaPage.applications.faceStatistics} alt="" />
          </div>
        </div>
        <div className="applications__content-box">
          <ApplicationsFeature data={applicationsData.faceStatistics} />
        </div>
      </div>
      <div className="download">
        <PdfDownload file={FacetaBrochureEn} productName="Faceta" />
      </div>
    </>
  );
};

ApplicationsAvailableLocations.propTypes = {
  locationsData: PropTypes.arrayOf(PropTypes.shape({
    content: PropTypes.element.isRequired,
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })).isRequired,
};

ApplicationsFeature.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.element,
    icon: PropTypes.string,
    locationsData: PropTypes.arrayOf(PropTypes.shape({
      content: PropTypes.element,
      icon: PropTypes.string,
      title: PropTypes.string,
    })),
    number: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

export default FacetaContent;
