// region 1. Platform Libraries
import React from 'react';
// endregion

// region 2. Project Libraries
import ArisanInfo from '../../components/ArisanInfo';
import Header from '../../components/Header';
import ClioContent from '../../components/ClioContent';
// endregion

// region U. UI Markups
import './Clio.scss';
// endregion

const Clio = () => (
  <div className="clio">
    <Header />
    <ClioContent />
    <ArisanInfo />
  </div>
);

export default Clio;
