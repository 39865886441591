// region U. UI Markups
import problem1 from './gifs/problem_1.gif';
import problem2 from './gifs/problem_2.gif';
import problem3 from './gifs/problem_3.gif';
import iconConstruction from './icon-construction.svg';
import iconCustomSolutions from './icon-custom-solutions.svg';
import iconDeviceHealthMonitoring from './icon-device-health-monitoring.svg';
import iconExhibition from './icon-exhibition.svg';
import iconFlexible from './icon-flexible.svg';
import iconInstantDeployment from './icon-instant-deployment.svg';
import iconLowMaintenance from './icon-low-maintenance.svg';
import iconManufacturing from './icon-manufacturing.svg';
import iconOffice from './icon-office.svg';
import iconPdfDownload from './icon-pdf-download.svg';
import iconPublicSafety from './icon-public-safety.svg';
import iconQuotationBottom from './icon-quotation-bottom.svg';
import iconQuotationTop from './icon-quotation-top.svg';
import iconReadyToUse from './icon-ready-to-use.svg';
import iconRec from './icon-rec.svg';
import iconRedCross from './icon-red-cross.svg';
import iconResidential from './icon-residential.svg';
import iconRetail from './icon-retail.svg';
import iconScrollDown from './icon-scroll-down.svg';
import iconServer from './icon-server.svg';
import iconServicesAccessControl from './icon-services-access-control.svg';
import iconServicesBlocklist from './icon-services-blocklist.svg';
import iconServicesDeviceHealthMonitoring from './icon-services-device-health-monitoring.svg';
import iconServicesFaceStatistics from './icon-services-face-statistics.svg';
import iconServicesIncidentManagement from './icon-services-incident-management.svg';
import iconServicesLiveBroadcasting from './icon-services-live-broadcasting.svg';
import iconServicesVideoArchive from './icon-services-video-archive.svg';
import iconServicesVip from './icon-services-vip.svg';
import iconSinglePlatformManagement from './icon-single-platform-management.svg';
import imgClioMockup from './img-clio.png'; // svg file causes browser taking too long to load the page
import imgFaceta from './img-faceta.png'; // svg file causes browser taking too long to load the page
import iconLeftControlMobile from './mobile/icon-left-control-m.svg';
import iconMoreMobile from './mobile/icon-more-m.svg';
import iconRightControlMobile from './mobile/icon-right-control-m.svg';
// endregion

const homePage = {
  rec: {
    icon: iconRec,
  },
  scrollDown: {
    icon: iconScrollDown,
  },
  problem_1: {
    gif: problem1,
  },
  problem_2: {
    gif: problem2,
  },
  problem_3: {
    gif: problem3,
  },
  deviceHealthMonitoring: {
    icon: iconDeviceHealthMonitoring,
  },
  singlePlatformManagement: {
    icon: iconSinglePlatformManagement,
  },
  instantDeployment: {
    icon: iconInstantDeployment,
  },
  topQuotation: {
    icon: iconQuotationTop,
  },
  bottomQuotation: {
    icon: iconQuotationBottom,
  },
  pdfDownload: {
    icon: iconPdfDownload,
  },
  servicesDeviceHealthMonitoring: {
    icon: iconServicesDeviceHealthMonitoring,
  },
  servicesIncidentManagement: {
    icon: iconServicesIncidentManagement,
  },
  servicesLiveBroadcasting: {
    icon: iconServicesLiveBroadcasting,
  },
  servicesVideoArchive: {
    icon: iconServicesVideoArchive,
  },
  cliOMockup: {
    img: imgClioMockup,
  },
  imgFaceta: {
    img: imgFaceta,
  },
  servicesAccessControl: {
    icon: iconServicesAccessControl,
  },
  servicesVip: {
    icon: iconServicesVip,
  },
  servicesBlockList: {
    icon: iconServicesBlocklist,
  },
  servicesFaceStatistics: {
    icon: iconServicesFaceStatistics,
  },
  server: {
    icon: iconServer,
  },
  readyToUse: {
    icon: iconReadyToUse,
  },
  lowMaintenance: {
    icon: iconLowMaintenance,
  },
  flexible: {
    icon: iconFlexible,
  },
  manufacturing: {
    icon: iconManufacturing,
  },
  retail: {
    icon: iconRetail,
  },
  office: {
    icon: iconOffice,
  },
  construction: {
    icon: iconConstruction,
  },
  residential: {
    icon: iconResidential,
  },
  publicSafety: {
    icon: iconPublicSafety,
  },
  exhibition: {
    icon: iconExhibition,
  },
  customSolutions: {
    icon: iconCustomSolutions,
  },
  more: {
    iconM: iconMoreMobile,
  },
  rightControl: {
    iconM: iconRightControlMobile,
  },
  leftControl: {
    iconM: iconLeftControlMobile,
  },
  redCross: {
    icon: iconRedCross,
  },
};

export { homePage as default };
