// region U. UI Markups
import headerCover from './header_cover.png';
import headerCoverMobile from './header_cover_mobile.png';
import deployments from './key_features_deployments.svg';
import management from './key_features_management.svg';
import platform from './key_features_platform.svg';
import deviceHealthMonitoring from './platform_device_health_monitoring.svg';
import eventNotifications from './platform_event_notifications.svg';
import liveAndPlayback from './platform_live_and_playback.svg';
import safeAndSecure from './platform_safe_and_secure.svg';
import dvr from './product_ahd_dvr.svg';
import bullet from './product_bullet.svg';
import dome from './product_dome.svg';
import nvr from './product_nvr.svg';
import series from './series.svg';
// endregion

const peer = {
  headerCover: {
    desktop: headerCover,
    mobile: headerCoverMobile,
  },
  keyFeatures: {
    deployments,
    management,
    platform,
  },
  platform: {
    deviceHealthMonitoring,
    eventNotifications,
    liveAndPlayback,
    safeAndSecure,
  },
  product: {
    bullet,
    dome,
    dvr,
    nvr,
  },
  series,
};

export { peer as default };
