// region 1. Platform Libraries
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
// endregion

// region 2. Project Libraries
import NavM from './mobile/NavM';
import SubAboutUs from './SubAboutUs';
import SubServices from './SubServices';
// endregion

// region U. UI Markups
import images from '../../assets/images';
import './Nav.scss';
// endregion

class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navbarBackground: '', // depend on which page
      navbarIsVisible: false, // check home page navbar background visible is ready or not
      navbarLogoLink: '', // if is home page, logo should disable pointer-event
      navbarServicesBackground: '', // show services background or not
      navbarServicesBorder: '', // show services border or not
      navbarCasesBackground: '', // show Case background or not
      navbarCasesBorder: '', // show Case border or not
      navbarAboutUsBackground: '', // show AboutUs background or not
      navbarAboutUsBorder: '', // show AboutUs border or not
      navbarHasBeenMoved: false, // check navbar has been moved or not
    };
  }

  componentDidMount() {
    this.checkIsHome();
  }

  componentWillUnmount() {
    clearInterval(this.setNavbarItem);
  }

  checkIsHome = () => {
    const { isHome } = this.props;
    const navbarBackground = isHome === 'true' ? 'navbar-home-background' : 'navbar-default-background';
    const navbarLogoLink = isHome === 'true' ? 'navbar__logo--link-disabled' : '';
    this.setState({
      navbarBackground,
      navbarLogoLink,
    });
  }

  overNavbar = () => {
    const { isHome } = this.props;
    if (isHome === 'true') {
      this.setNavbarIsVisible = setTimeout(() => {
        const { navbarIsVisible } = this.state;
        if (navbarIsVisible === false) {
          this.setState({ navbarIsVisible: true });
        }
      }, 500);
      this.setState({
        navbarBackground: 'navbar-home-background__visible',
      });
    }
  };

  leaveNavbar = () => {
    const { navbarIsVisible } = this.state;
    const { isHome } = this.props;
    if (isHome === 'true') {
      this.setState({ navbarBackground: 'navbar-home-background' });
      if (navbarIsVisible === true) {
        this.setState({ navbarIsVisible: false });
      }
      clearTimeout(this.setNavbarIsVisible);
    }
  }

  overNavbarItem = (item) => {
    const { isHome } = this.props;
    if (isHome === 'true') {
      this.setNavbarItem = setInterval(() => {
        const { navbarIsVisible } = this.state;
        const navbarItemVisible = navbarIsVisible === true ? '--visible' : '';
        if (navbarItemVisible === '--visible') {
          clearInterval(this.setNavbarItem);
        }
        switch (item) {
          case 'Cases':
            this.setState({
              navbarCasesBackground: navbarItemVisible,
              navbarCasesBorder: navbarItemVisible,
            });
            break;
          case 'AboutUs':
            this.setState({
              navbarAboutUsBackground: navbarItemVisible,
              navbarAboutUsBorder: navbarItemVisible,
            });
            break;
          default:
            this.setState({
              navbarServicesBackground: navbarItemVisible,
              navbarServicesBorder: navbarItemVisible,
            });
        }
      }, 100);
    } else {
      const navbarItemVisible = '--visible';
      switch (item) {
        case 'Cases':
          this.setState({
            navbarCasesBackground: navbarItemVisible,
            navbarCasesBorder: navbarItemVisible,
          });
          break;
        case 'AboutUs':
          this.setState({
            navbarAboutUsBackground: navbarItemVisible,
            navbarAboutUsBorder: navbarItemVisible,
          });
          break;
        default:
          this.setState({
            navbarServicesBackground: navbarItemVisible,
            navbarServicesBorder: navbarItemVisible,
          });
      }
    }
  }

  leaveNavbarItem = (item) => {
    clearInterval(this.setNavbarItem);
    const navbarItemVisible = '';
    switch (item) {
      case 'Cases':
        this.setState({
          navbarCasesBackground: navbarItemVisible,
          navbarCasesBorder: navbarItemVisible,
        });
        break;
      case 'AboutUs':
        this.setState({
          navbarAboutUsBackground: navbarItemVisible,
          navbarAboutUsBorder: navbarItemVisible,
        });
        break;
      default:
        this.setState({
          navbarServicesBackground: navbarItemVisible,
          navbarServicesBorder: navbarItemVisible,
        });
    }
  }

  moveNavbar = () => {
    const { isHome } = this.props;
    const { navbarHasBeenMoved } = this.state;
    if (isHome === 'true' && navbarHasBeenMoved === false) {
      this.setState({
        navbarHasBeenMoved: true,
      });
      this.overNavbar();
    }
  }

  moveServiceItem = () => {
    const { isHome } = this.props;
    if (isHome !== 'true') {
      const navbarItemVisible = '--visible';
      this.setState({
        navbarServicesBackground: navbarItemVisible,
        navbarServicesBorder: navbarItemVisible,
      });
    }
  }

  render() {
    const {
      navbarLogoLink, navbarBackground, navbarServicesBackground,
      navbarServicesBorder, navbarAboutUsBackground,
      navbarAboutUsBorder, navbarCasesBackground, navbarCasesBorder,
    } = this.state;
    return (
      <>
        <NavM />
        <nav
          className={`navbar ${navbarBackground}`}
          onMouseEnter={this.overNavbar}
          onMouseLeave={this.leaveNavbar}
          onMouseMove={this.moveNavbar}
        >
          <div className="navbar__nav">
            <div className="navbar__logo">
              <Link className={navbarLogoLink} to="/">
                <img src={images.arisan.logo} alt="" />
              </Link>
            </div>
            <div
              className={`navbar__nav-item${navbarServicesBackground}`}
              onMouseEnter={(e) => this.overNavbarItem('Services', e)}
              onMouseLeave={(e) => this.leaveNavbarItem('Services', e)}
              onMouseMove={this.moveServiceItem}
            >
              <Link className="navbar__nav-item--link" to="/services">
                <span className={`navbar__nav-item-text navbar__nav-item-text${navbarServicesBorder}`}>
                  SERVICES
                </span>
              </Link>
              <SubServices navbarServicesBorder={navbarServicesBorder} />
            </div>
            <div
              className={`navbar__nav-item${navbarCasesBackground} disabled`}
              onMouseEnter={(e) => this.overNavbarItem('Cases', e)}
              onMouseLeave={(e) => this.leaveNavbarItem('Cases', e)}
            >
              <Link className="navbar__nav-item--link" to="/cases">
                <span className={`navbar__nav-item-text navbar__nav-item-text${navbarCasesBorder}`}>
                  CASES
                </span>
              </Link>
            </div>
            <div
              className={`navbar__nav-item${navbarAboutUsBackground}`}
              onMouseEnter={(e) => this.overNavbarItem('AboutUs', e)}
              onMouseLeave={(e) => this.leaveNavbarItem('AboutUs', e)}
            >
              <Link className="navbar__nav-item--link" to="/about-us">
                <span className={`navbar__nav-item-text navbar__nav-item-text${navbarAboutUsBorder}`}>
                  ABOUT US
                </span>
              </Link>
              <SubAboutUs navbarAboutUsBorder={navbarAboutUsBorder} />
            </div>
          </div>
        </nav>
      </>
    );
  }
}

Nav.propTypes = {
  isHome: PropTypes.string,
};

Nav.defaultProps = {
  isHome: '',
};

export default Nav;
