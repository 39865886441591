// region U. UI Markups
import builtInIcons from './built_in_icons.png';
import builtInIconsMobile from './built_in_icons_mobile.png';
import centralManagementIcon from './central_management_icon.svg';
import centralManagementNumber from './central_management_number.svg';
import headerCover from './header_cover.png';
import headerCoverMobile from './header_cover_mobile.png';
import designedByIcon from './designed_by_icon.svg';
import designedByNumber from './designed_by_number.svg';
import deviceHealthMonitoringIcon from './device_health_monitoring_icon.svg';
import deviceHealthMonitoringImpression from './device_health_monitoring_impression.png';
import deviceHealthMonitoringLine from './device_health_monitoring_line.svg';
import deviceHealthMonitoringLineMobile from './device_health_monitoring_line_mobile.svg';
import fullCompatibilityIcon from './full_compatibility_icon.svg';
import fullCompatibilityNumber from './full_compatibility_number.svg';
import highAvailabilityIcon from './high_availability_icon.svg';
import highAvailabilityNumber from './high_availability_number.svg';
import howItWorksIllustration from './how_it_works_illustration.png';
import howItWorksIllustrationMobile from './how_it_works_illustration_mobile.png';
import incidentManagementIcon from './incident_management_icon.svg';
import incidentManagementImpression from './incident_management_impression.png';
import incidentManagementImpressionMobile from './incident_management_impression_mobile.png';
import instantDeploymentIcon from './instant_deployment_icon.svg';
import instantDeploymentNumber from './instant_deployment_number.svg';
import keyFeatureConstructionSites from './key_features_construction_sites.png';
import keyFeatureConvenientStores from './key_features_convenient_stores.png';
import keyFeatureSupermarkets from './key_features_supermarkets.png';
import liveBroadcastingIcon from './live_broadcasting_icon.svg';
import liveBroadcastingImpression from './live_broadcasting_impression.png';
import liveBroadcastingX1000 from './live_broadcasting_x_1000.png';
import liveBroadcastingX1000Mobile from './live_broadcasting_x_1000_mobile.png';
import noMaintenanceIcon from './no_maintenance_icon.svg';
import noMaintenanceNumber from './no_maintenance_number.svg';
import solidSecurityIcon from './solid_security_icon.svg';
import solidSecurityNumber from './solid_security_number.svg';
import videoArchiveIcon from './video_archive_icon.svg';
import videoArchiveImpression from './video_archive_impression.png';
import whatIsClioImages from './what_is_clio_images.png';
// endregion

const clio = {
  builtIn: {
    icons: {
      desktop: builtInIcons,
      mobile: builtInIconsMobile,
    },
  },
  headerCover: {
    desktop: headerCover,
    mobile: headerCoverMobile,
  },
  deviceHealthMonitoring: {
    icon: deviceHealthMonitoringIcon,
    impression: deviceHealthMonitoringImpression,
    line: {
      desktop: deviceHealthMonitoringLine,
      mobile: deviceHealthMonitoringLineMobile,
    },
  },
  fullCompatibility: {
    icon: fullCompatibilityIcon,
    number: fullCompatibilityNumber,
  },
  noMaintenance: {
    icon: noMaintenanceIcon,
    number: noMaintenanceNumber,
  },
  centralManagement: {
    icon: centralManagementIcon,
    number: centralManagementNumber,
  },
  instantDeployment: {
    icon: instantDeploymentIcon,
    number: instantDeploymentNumber,
  },
  solidSecurity: {
    icon: solidSecurityIcon,
    number: solidSecurityNumber,
  },
  highAvailability: {
    icon: highAvailabilityIcon,
    number: highAvailabilityNumber,
  },
  designedBy: {
    icon: designedByIcon,
    number: designedByNumber,
  },
  howItWorksIllustration: {
    desktop: howItWorksIllustration,
    mobile: howItWorksIllustrationMobile,
  },
  incidentManagement: {
    icon: incidentManagementIcon,
    impression: {
      desktop: incidentManagementImpression,
      mobile: incidentManagementImpressionMobile,
    },
  },
  keyFeatures: {
    constructionSites: keyFeatureConstructionSites,
    convenientStores: keyFeatureConvenientStores,
    supermarkets: keyFeatureSupermarkets,
  },
  liveBroadcasting: {
    icon: liveBroadcastingIcon,
    impression: liveBroadcastingImpression,
    x1000: {
      desktop: liveBroadcastingX1000,
      mobile: liveBroadcastingX1000Mobile,
    },
  },
  videoArchive: {
    icon: videoArchiveIcon,
    impression: videoArchiveImpression,
  },
  whatIsClioImages,
};

export { clio as default };
