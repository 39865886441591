// region U. UI Markups
import arisanVideo from './arisan-video.mp4';
import facetaHowItWorks from './faceta_how_it_works.mp4';
import peerInfrastructureHorizontal from './peer_infrastructure_horizontal.mp4';
import peerInfrastructureVertical from './peer_infrastructure_vertical.mp4';
// endregion

const videos = {
  arisanVideo,
  facetaHowItWorks,
  peerInfrastructure: {
    horizontal: peerInfrastructureHorizontal,
    vertical: peerInfrastructureVertical,
  },
};

export { videos as default };
