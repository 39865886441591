// region 1. Platform Libraries
import React from 'react';
// endregion

// region U. UI Markups
import images from '../../assets/images';
import './AboutUsBlockHeader.scss';
// endregion

const AboutUsBlockHeader = () => (
  <>
    <div className="about-us-header__container">
      <div className="about-us-header__container__red" />
      <div className="about-us-header__container__building">
        <img src={images.aboutUs.building} alt="" />
      </div>
      <div className="about-us-header__container__title">
        ABOUT US
      </div>
    </div>
    <div className="about-us-header__extends">
      <div className="about-us-header__extends__left">
        <div className="about-us-header__extends__left--red" />
        <div className="about-us-header__extends__left--building" />
      </div>
      <div className="about-us-header__extends__right" />
    </div>
  </>
);

export default AboutUsBlockHeader;
