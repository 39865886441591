// region 1. Platform Libraries
import React, { useContext } from 'react';
// endregion

// region 2. Project Libraries
import ContextStore from '../../store/Context';
// endregion

// region U. UI Markups
import images from '../../assets/images';
import ArisanTitle from '../Utils/ArisanTitle';
import './ContactUs.scss';
// endregion

const ContactInformation = () => {
  const { demo, info, pricing } = images.contactUs;
  const contactData = [
    { text: 'Info', type: 'More Information', image: info },
    { text: 'Demo', type: 'Demo Request', image: demo },
    { text: 'Pricing', type: 'Price Inquiry', image: pricing },
  ];

  const { showContactForm } = useContext(ContextStore);

  return (
    <div className="contact-us-section__form-container">
      {
        contactData.map((data) => {
          const { text, type, image } = data;
          return (
            <button
              className="contact-information"
              key={text}
              type="button"
              onClick={() => showContactForm(type)}
            >
              <div className="contact-information__image">
                <img src={image} alt="" />
              </div>
              <div className="contact-information__button">
                <div className="contact-information__button--text">
                  {`${text} >`}
                </div>
              </div>
            </button>
          );
        })
      }
    </div>
  );
};

const ContactUs = () => (
  <div className="contact-us-section">
    <div className="contact-us-section__title">
      <ArisanTitle title="CONTACT US" className="home" />
    </div>
    <div className="contact-us-section__content">
      <ContactInformation />
    </div>
  </div>
);

export default ContactUs;
