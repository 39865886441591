// region 1. Platform Libraries
import PropTypes from 'prop-types';
import React from 'react';
// endregion

// region U. UI Markups
import { clioPage, facetaPage, peerPage } from '../../assets/images';
import './ImageHeader.scss';
// endregion

const BlockHeader = ({ page }) => (
  <header className={`header-image${page === 'peer' ? '__peer' : ''}`}>
    {page === 'clio' && (
      <picture>
        <source
          media="(max-width: 1365px)"
          srcSet={clioPage.headerCover.mobile}
        />
        <img
          src={clioPage.headerCover.desktop}
          alt="header cover"
        />
      </picture>
    )}
    {page === 'faceta' && (
      <picture>
        <source
          media="(max-width: 1365px)"
          srcSet={facetaPage.headerCover.mobile}
        />
        <img
          src={facetaPage.headerCover.desktop}
          alt="header cover"
        />
      </picture>
    )}
    {page === 'peer' && (
      <picture>
        <source
          media="(max-width: 1365px)"
          srcSet={peerPage.headerCover.mobile}
        />
        <img
          src={peerPage.headerCover.desktop}
          alt="header cover"
        />
      </picture>
    )}
  </header>
);

BlockHeader.propTypes = {
  page: PropTypes.string.isRequired,
};

export default BlockHeader;
