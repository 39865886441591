// region 1. Platform Libraries
import React from 'react';
// endregion

// region 2. Project Libraries
import Carousel from '../Carousel';
import ArisanTitle from '../Utils/ArisanTitle';
import ApplicationIcon from './ApplicationIcon';
// endregion

// region U. UI Markups
import { homePage } from '../../assets/images';
import './Application.scss';
// endregion

const slides = [
  {
    image: homePage.manufacturing.icon,
    name: 'Manufacturing',
  },
  {
    image: homePage.retail.icon,
    name: 'Retail',
  },
  {
    image: homePage.office.icon,
    name: 'Office',
  },
  {
    image: homePage.construction.icon,
    name: 'Construction',
  },
  {
    image: homePage.residential.icon,
    name: 'Residential',
  },
  {
    image: homePage.publicSafety.icon,
    name: 'Public Safety',
  },
  {
    image: homePage.exhibition.icon,
    name: 'Exhibition',
  },
  {
    image: homePage.customSolutions.icon,
    name: 'Custom Solutions',
  },
];

const Application = () => (
  <div className="applications-section">
    <div className="applications-section__title">
      <ArisanTitle
        className="home"
        title="APPLICATIONS"
      />
    </div>
    <Carousel
      hasControl
      hasIndicator={false}
      slides={slides}
    />
    <div className="applications-section__icons-container">
      {slides.map((slide) => (<ApplicationIcon icon={slide} key={slide.name} />))}
    </div>
  </div>
);

export default Application;
