// region 1. Platform Libraries
import React from 'react';
// endregion

// region U. UI Markups
import './FacetaBackground.scss';
// endregion

const FacetaBackground = () => (
  <div className="faceta-background">
    <div className="faceta-background__header">
      <div className="faceta-background__header--blue" />
      <div className="faceta-background__header--image" />
    </div>
    <div className="faceta-background__key-features">
      <div className="faceta-background__key-features--block" />
      <div className="faceta-background__key-features--black" />
    </div>
    <div className="faceta-background__applications" />
    <div className="faceta-background__download" />
  </div>
);

export default FacetaBackground;
