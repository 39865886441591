// region 1. Platform Libraries
import React from 'react';
// endregion

// region U. UI Markups
import './PeerBackground.scss';
// endregion

const PeerBackground = () => (
  <div className="peer-background">
    <div className="peer-background__header">
      <div className="peer-background__header__image" />
      <div className="peer-background__header__purple" />
      <div className="peer-background__header__gray" />
    </div>
    <div className="peer-background__peer-series" />
    <div className="peer-background__key-features" />
    <div className="peer-background__infrastructure">
      <div className="peer-background__infrastructure__gray" />
    </div>
    <div className="peer-background__clio-cloud-platform" />
    <div className="peer-background__hardware-products" />
    <div className="peer-background__download" />
  </div>
);

export default PeerBackground;
