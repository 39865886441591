// region 1. Platform Libraries
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
// endregion

// region U. UI Markups
import images from '../../../assets/images';
import './NavM.scss';
// endregion

const MenuLink = ({
  name, url, isLastItem, type, isTopTitle,
}) => (
  <Link
    to={url}
    className={`
    arisan-h2-normal 
    menu__${type} 
    ${isLastItem ? 'menu__item--last' : ''} 
    ${isTopTitle ? 'menu__title--top' : ''}
    `}
  >
    {name}
  </Link>
);

MenuLink.propTypes = {
  isLastItem: PropTypes.bool,
  isTopTitle: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string,
};

MenuLink.defaultProps = {
  isLastItem: false,
  isTopTitle: false,
  name: '',
  type: '',
  url: '',
};

const NavM = () => {
  const [checked, setChecked] = useState(true);
  const { pathname } = useLocation();

  const handleLinkClick = (targetLink) => {
    if (targetLink && targetLink.split('/')[1] === pathname.split('/')[1]) {
      setChecked(true);
    }
  };

  return (
    <nav className="m-navbar">
      <input
        className="m-navbar__menu-toggler"
        id="m-navbar__menu-toggler"
        type="checkbox"
        checked={checked}
        onChange={() => setChecked(!checked)}
      />
      <div className="m-navbar__nav">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label
          className="m-navbar__hamburger"
          htmlFor="m-navbar__menu-toggler"
        >
          <img src={images.arisan.hamburger} alt="" />
        </label>
        <div className="m-navbar__logo">
          <Link to="/">
            <img src={images.arisan.logoM} alt="" />
          </Link>
        </div>
        {/* <div className="m-navbar__earth">
          <img src={images.arisan.earth} alt="" />
        </div> */}
      </div>
      <div
        className="m-navbar__menu"
        role="link"
        tabIndex="0"
        onClick={(e) => handleLinkClick(e.target.getAttribute('href'))}
        aria-hidden="true"
      >
        <div className="menu__section">
          <MenuLink name="SERVICES" url="/services" type="title" isTopTitle />
          <MenuLink name="CliO" url="/clio" type="item" />
          <MenuLink name="Faceta" url="/faceta" type="item" />
          <MenuLink name="Peer" url="/peer" type="item" />
          <MenuLink name="CUSTOM SOLUTIONS" url="/custom-solutions" type="item" isLastItem />
        </div>
        {/* <div className="menu__section">
          <MenuLink name="CASES" url="/" type="title" />
          <MenuLink name="APPLICATIONS" url="/" type="item" />
          <MenuLink name="CASES" url="/" type="item" isLastItem />
        </div> */}
        <div className="menu__section">
          <MenuLink name="ABOUT US" url="/about-us" type="title" />
          <MenuLink name="MISSION" url="/about-us/mission" type="item" />
          <MenuLink name="TEAM" url="/about-us/team" type="item" isLastItem />
        </div>
      </div>
    </nav>
  );
};

export default NavM;
